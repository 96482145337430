import { Icon, Label, Link } from '@fluentui/react';
import React from 'react';
import { getContractingUnit, getProject, hasPermissions } from '../../util';
import i18n from '../../../../i18n';
import { uPrinceTheme } from './../../../../theme';

export interface MenuStratergy {
  renderMenu(): any;
}

export class PMOLMenuStratergy implements MenuStratergy {
  projectMap: Map<string, any[]>;

  constructor(currentUser: any, orgnization: string) {
    const isProjectManager = hasPermissions(
      currentUser?.groups,
      orgnization,
      getContractingUnit()?.replace('-', '')
    ).isProjectManager;
    const isCustomerPortalUser = hasPermissions(
      currentUser?.groups,
      orgnization,
      getContractingUnit()?.replace('-', '')
    ).isCustomerPortalUser;
    this.projectMap = !isCustomerPortalUser
      ? new Map([
          [
            i18n.t('pojectMolecule'),
            [
              {
                name: i18n.t('projectDefinition'),
                icon: 'ProjectLogoInverse',
                url: `/CU/${getContractingUnit()}/project/${getProject()}/project-definition`,
                enable: true,
              },
              {
                name: i18n.t('projectBreakdownStructure'),
                icon: 'Org',
                url: `/CU/${getContractingUnit()}/project/${getProject()}/product/pbs`,
                enable: true,
              },
              {
                name: i18n.t('corporateProductCatalogue'),
                icon: 'ProductCatalog',
                url: `/CU/${getContractingUnit()}/project/${getProject()}/product-catalogue`,
                enable: true,
              },
              {
                name: i18n.t('billOfResources'),
                icon: 'Calculator',
                url: `/CU/${getContractingUnit()}/project/${getProject()}/bill-Of-resources`,
                enable: true,
              },
              {
                name: i18n.t('pojectMolecule'),
                icon: 'EngineeringGroup',
                url: `/CU/${getContractingUnit()}/project/${getProject()}/project-molecule`,
                enable: true,
              },
              {
                name: i18n.t('progressStatement'),
                icon: 'TableGroup',
                url: `/CU/${getContractingUnit()}/project/${getProject()}/progress-statement`,
                enable: isProjectManager,
              },
              {
                name: i18n.t('invoice'),
                icon: 'M365InvoicingLogo',
                url: `/CU/${getContractingUnit()}/project/${getProject()}/invoice`,
                enable: isProjectManager,
              },
            ],
          ],
          [
            'Enterprise Resource',
            [
              {
                name: i18n.t('purchaseOrder'),
                icon: 'ActivateOrders',
                url: `/CU/${getContractingUnit()}/project/${getProject()}/purchase-order`,
                enable: true,
              },
              {
                name: i18n.t('stock'),
                icon: 'Archive',
                url: `/CU/${getContractingUnit()}/project/${getProject()}/stock`,
                enable: true,
              },
              {
                name: i18n.t('warehouse'),
                icon: 'Home',
                url: `/CU/${getContractingUnit()}/project/${getProject()}/stock-warehouse`,
                enable: true,
              },
              {
                name: i18n.t('workFlow'),
                icon: 'Flow',
                url: `/CU/${getContractingUnit()}/project/${getProject()}/stock-workflow`,
                enable: true,
              },
            ],
          ],
          [
            'Visual Planing',
            [
              {
                name: i18n.t('dayPlanning'),
                icon: 'ActivateOrders',
                url: `/CU/${getContractingUnit()}/project/${getProject()}/day-planning`,
                enable: true,
              },
              {
                name: i18n.t('projectPlaning'),
                icon: 'ActivateOrders',
                url: `/CU/${getContractingUnit()}/project/${getProject()}/project-planning`,
                enable: true,
              },
            ],
          ],
          ['XRM', []],
        ])
      : new Map([
          [
            'Visual Planing',
            [
              {
                name: i18n.t('projectPlaning'),
                icon: 'ActivateOrders',
                url: `/CU/${getContractingUnit()}/project/${getProject()}/project-planning?from-plan-board=true`,
                enable: true,
              },
            ],
          ],
        ]);
  }

  renderMenu(): any {
    return <MenuTable itemMap={this.projectMap} />;
  }
}

export class OrganizationMenuStratergy implements MenuStratergy {
  projectMap: Map<string, any[]>;

  constructor(currentUser: any, orgnization: string) {
    const hasOrganisationPermission = hasPermissions(
      currentUser?.groups,
      orgnization
    ).hasOrganisationPermission;
    this.projectMap = new Map([
      [
        i18n.t('organisation'),
        [
          {
            name: i18n.t('contractingUnit'),
            url: '/contracting-unit',
            icon: 'EMI',
            enable: true,
          },
          {
            name: i18n.t('centralAddressBook'),
            url: '/address-book',
            icon: 'ConnectContacts',
            enable: hasOrganisationPermission,
          },
          {
            name: i18n.t('corporateProductCatalogue'),
            url: '/product-catalogue',
            icon: 'ProductCatalog',
            enable: hasOrganisationPermission,
          },
        ],
      ],
    ]);
  }

  getMenuItemArray = () => {};

  renderMenu(): any {
    return <MenuTable itemMap={this.projectMap} />;
  }
}

export class CUMenuStratergy implements MenuStratergy {
  projectMap: Map<string, any[]>;

  constructor(currentUser: any, orgnization: string) {
    const hasCUPermission = hasPermissions(
      currentUser?.groups,
      orgnization,
      getContractingUnit()?.replace('-', '')
    ).hasContractingUnitPermission;
    const isProjectManager = hasPermissions(
      currentUser?.groups,
      orgnization,
      getContractingUnit()?.replace('-', '')
    ).isProjectManager;
    this.projectMap = new Map([
      [
        i18n.t('contractingUnit'),
        [
          {
            name: i18n.t('project'),
            icon: 'ProjectLogoInverse',
            url: `/CU/${getContractingUnit()}`,
            enable: true,
          },
          {
            name: i18n.t('timeClock'),
            icon: 'Clock',
            url: `/CU/${getContractingUnit()}/time_clock`,
            enable: hasCUPermission,
          },
          {
            name: i18n.t('qRCode'),
            icon: 'QRCode',
            url: `/CU/${getContractingUnit()}/qr-code`,
            enable: hasCUPermission,
          },
          {
            name: i18n.t('corporateProductCatalogue'),
            url: `/CU/${getContractingUnit()}/product-catalogue`,
            icon: 'ProductCatalog',
            enable: hasCUPermission,
          },
          {
            name: i18n.t('progressStatement'),
            icon: 'TableGroup',
            url: `/CU/${getContractingUnit()}/progress-statement`,
            enable: isProjectManager,
          },
          {
            name: i18n.t('invoice'),
            url: `/CU/${getContractingUnit()}/invoice`,
            icon: 'M365InvoicingLogo',
            enable: isProjectManager,
          },
        ],
      ],
      [
        'Visual Planing',
        [
          {
            name: i18n.t('visualPlanning'),
            icon: 'ActivateOrders',
            url: `/CU/${getContractingUnit()}/visual-plan`,
            enable: true,
          },
          {
            name: i18n.t('dayPlanning'),
            icon: 'Archive',
            url: `/CU/${getContractingUnit()}/day-planning`,
            enable: true,
          },
        ],
      ],
    ]);
  }

  renderMenu(): any {
    return <MenuTable itemMap={this.projectMap} />;
  }
}

export class ApplicationMenuStratergy implements MenuStratergy {
  projectMap: Map<string, any[]>;

  constructor(currentUser: any, orgnization: string) {
    const isApplicationManager = hasPermissions(
      currentUser?.groups,
      orgnization,
      getContractingUnit()?.replace('-', '')
    ).isApplicationManager;
    this.projectMap = new Map([
      [
        i18n.t('application'),
        [
          {
            name: i18n.t('dropdownTranslation'),
            url: '/dropdown-translations-dashboard',
            icon: 'Translate',
            enable: isApplicationManager,
          },
          {
            name: i18n.t('organisationSetting'),
            url: '/organisation-settings',
            icon: 'Settings',
            enable: isApplicationManager,
          },
          {
            name: i18n.t('translation'),
            url: '/translations-dashboard',
            icon: 'Translate',
            enable: isApplicationManager,
          },
        ],
      ],
    ]);
  }

  renderMenu(): any {
    return <MenuTable itemMap={this.projectMap} />;
  }
}

export class UnderConstructMenuStratergy implements MenuStratergy {
  renderMenu(): any {
    return <UnderConstructComponent msg={i18n.t('menuUnderConstruct')} />;
  }
}

export const UnderConstructComponent = (props: { msg: string }) => {
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: 120,
        paddingLeft: 0,
      }}
    >
      <Icon iconName="ConstructionConeSolid" style={{ fontSize: 45 }} />
      <Label>{props.msg}</Label>
    </div>
  );
};

export const MenuTable = (props: { itemMap: any }) => {
  const listStyle = {
    marginTop: 14,
    marginBottom: 0,
    listStyleType: 'none',
  };

  const renderTableHeader = () => {
    const header: any = Array.from(props.itemMap.keys());
    return header.map((key: string, index: number) => {
      return (
        <th
          key={index}
          style={{
            width: 180,
            height: 31,
            paddingTop: 10,
            marginTop: -5,
            background: uPrinceTheme.palette.themePrimary,
            paddingBottom: 10,
            color: uPrinceTheme.palette.white,

            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {key.toUpperCase()}
        </th>
      );
    });
  };

  const renderCategoryItems = (mapKey: string) => {
    const itemList:
      | { name: string; url: string; enable: boolean; icon: string }[]
      | undefined = props.itemMap.get(mapKey);
    const listItems = itemList?.map((item, index) => (
      <li key={index} style={listStyle}>
        <Icon
          iconName={item.icon}
          style={{
            fontSize: 15,
            paddingRight: 10,
            color: item.enable
              ? uPrinceTheme.palette.themePrimary
              : uPrinceTheme.palette.neutralTertiaryAlt,
          }}
        />
        <Link disabled={!item.enable} href={item.url}>
          {item.name}
        </Link>
      </li>
    ));
    return listItems;
  };

  const renderTableData = () => {
    const header: any = Array.from(props.itemMap.keys());
    return (
      <tr key={1}>
        {header.map((key1: string, index1: number) => (
          <td key={index1} style={{ paddingLeft: 20, verticalAlign: 'top' }}>
            {renderCategoryItems(header[index1])}
          </td>
        ))}
      </tr>
    );
    // });
  };

  return (
    <table
      id="menu"
      style={{
        marginLeft: 0,
        width: '100%',
        borderSpacing: '1px 0',
        paddingBottom: 20,
      }}
    >
      <tbody>
        <tr>{renderTableHeader()}</tr>
        {renderTableData()}
      </tbody>
    </table>
  );
};
