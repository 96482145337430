import React, { useEffect, useRef, useState } from 'react';
import { ViewMode } from '../../../shared/ganttv2';
import { Label } from 'office-ui-fabric-react';
import { ViewSwitcher } from './view-switcher';
import { ViewModeContext } from '../productTaxonomyListPane/component';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getMidtermCapacityData } from '../../../reducers/visualPlanReducer';
import { FontIcon, IComboBoxOption } from '@fluentui/react';
import { getBuDropdownData } from '../../../reducers/projectDayPlanningReducer';
import { store } from '../../../../index';
import { getBuGroups } from '../../../types/myDayPlanning';
import moment from 'moment/moment';
import { TableExpandProvider, useTableExpand } from '../../../hooks/useTableExpandContext';

const ROW_HEIGHT = 36;

const getBackgroundColor = (percentage: number | null): string | undefined => {
  if (percentage === null) {
    return undefined;
  }

  if (percentage <= 80) {
    return '#EC6981';
  } else if (percentage >= 81 && percentage < 110) {
    return '#A1DAA4';
  } else if (percentage >= 111 && percentage < 121) {
    return '#EFD047';
  } else {
    return '#EC6981';
  }
};

const weekendColor = '#4f7fef33';

const TableRow = ({ index, projectManagerName, mainValues, weeks }: any) => {
  const [expanded, setExpanded] = useState(false);
  const { isAllExpanded } = useTableExpand();

  useEffect(() => {
    if (isAllExpanded) {
      setExpanded(true);
    } else {
      setExpanded(false);
    }
  }, [isAllExpanded]);

  return (
    <>
      <tr
        style={{ height: ROW_HEIGHT, border: '1px solid #BFC9CA' }}
        key={index}
        onClick={() => {
          setExpanded(!expanded);
        }}
      >
        <th style={{
          minWidth: '30vw',
          backgroundColor: index % 2 === 0 ? '#F3F2F1' : 'white',
          height: ROW_HEIGHT,
          borderRight: '1px solid #BFC9CA',
          padding: 0,
        }}>
          <div style={{ width: 'calc(100% - 5px)', display: 'flex', flexDirection: 'row', marginLeft: 5, padding: 0, height: 'inherit', borderRight: '1px solid #BFC9CA' }}>
            <FontIcon
              aria-label='ChevronRight'
              style={{ marginTop: 10, cursor: 'pointer' }}
              iconName={expanded ? 'ChevronDown' : 'ChevronRight'}
            />
            <Label style={{ marginLeft: 10, fontSize: 14 }}>{projectManagerName}</Label>
          </div>
        </th>

        {/*In Week ViewMode, weeks is an object, not an array*/}
        {Object.entries(weeks)?.map(([]: any, childIndex: number) => (
          <td
            colSpan={7}
            key={childIndex}
            style={{
              backgroundColor: index % 2 === 0 ? '#F3F2F1' : 'white',
              minWidth: 60,
              height: ROW_HEIGHT,
              textAlignLast: 'end',
              paddingRight: 10,
              border: '1px solid #BFC9CA',
              fontFamily: 'Segoe UI',
              fontSize: 14,
            }}
          >
            {/*Project manager empty rows*/}
          </td>
        ))}
      </tr>
      {expanded && (
        <>
          {mainValues && mainValues?.children?.map((e: any, index: number) => {
            return <TableRowChild key={index} index={index} projectName={e.project} mainValues={e} />;
          })}
        </>
      )}
    </>
  );
};

const TableRowChild = ({ index, projectName, mainValues }: any) => {
  const [expandedChild, setExpandedChild] = useState(false);
  const { isAllExpanded } = useTableExpand();

  useEffect(() => {
    if (isAllExpanded) {
      setExpandedChild(true);
    } else {
      setExpandedChild(false);
    }
  }, [isAllExpanded]);

  return (
    <>
      <tr
        style={{
          height: ROW_HEIGHT,
          border: '1px solid #BFC9CA',
          backgroundColor: index % 2 === 0 ? '#F3F2F1' : 'white',
        }}
        key={index}
        onClick={() => {
          setExpandedChild(!expandedChild);
        }}
      >
        <th style={{
          minWidth: 60,
          backgroundColor: index % 2 === 0 ? '#F3F2F1' : 'white',
          height: ROW_HEIGHT,
          borderRight: '1px solid #BFC9CA',
          padding: 0,
        }}>
          <div style={{ width: 'calc(100% - 5px)', display: 'flex', flexDirection: 'row', marginLeft: 5, padding: 0, height: 'inherit', borderRight: '1px solid #BFC9CA' }}>
            <FontIcon
              aria-label='ChevronRight'
              style={{ marginLeft: 10, marginTop: 10, cursor: 'pointer' }}
              iconName={expandedChild ? 'ChevronDown' : 'ChevronRight'}
            />
            <Label style={{ marginTop: 4, marginLeft: 10, fontSize: 14 }}>{projectName}</Label>
          </div>
        </th>

        {Object.entries(mainValues?.parent?.hours)?.map(([key, totalProjectHours]: any, index: number) => (
          <td
            // colSpan={Object.entries(mainValues.parentData)?.length}
            key={index}
            style={{
              background: (index + 1) % 7 === 0 || (index + 2) % 7 === 0 ? weekendColor : undefined,
              minWidth: 60,
              height: ROW_HEIGHT,
              textAlignLast: 'end',
              paddingRight: 10,
              border: '1px solid #BFC9CA',
              fontFamily: 'Segoe UI',
              fontSize: 14,
            }}
          >
            {/*{totalProjectHours == "0" ? "" : totalProjectHours}*/}
            {totalProjectHours}
          </td>
        ))}

      </tr>
      {expandedChild && (
        <>
          {mainValues?.children.map((e: any, index: number) => {
            return (
              <tr
                className='expandRowMT'
                style={{
                  minWidth: 150,
                  height: ROW_HEIGHT,
                  border: '1px solid #BFC9CA',
                  backgroundColor: index % 2 === 0 ? '#F3F2F1' : 'white', // Whole row background color
                }}
                key={index}
              >
                <td style={{
                  minWidth: 60,
                  backgroundColor: index % 2 === 0 ? '#F3F2F1' : 'white',
                  height: ROW_HEIGHT,
                  borderRight: '1px solid #BFC9CA',
                  padding: 0,
                }}>
                  {/*Person Names*/}
                  <Label style={{ width: 'calc(100% - 64px)', height: 'inherit', marginBottom: -5, marginLeft: 65, fontSize: 14, borderRight: '1px solid #BFC9CA' }}>{e?.pearson}</Label>
                </td>
                {e?.hours?.map((personHours: any, index: number) => {
                    const percentage = e?.percentage && e?.percentage[index];
                    return (
                      <td
                        key={index}
                        style={{
                          background: (index + 1) % 7 === 0 || (index + 2) % 7 === 0 ? weekendColor : getBackgroundColor(percentage),
                          textAlignLast: 'end',
                          paddingRight: 10,
                          border: '1px solid #BFC9CA',
                          maxHeight: ROW_HEIGHT,
                        }}
                      >
                        <Label style={{ marginLeft: 10, fontSize: 14 }}>
                          {/*{personHours == "0" ? "" : personHours}*/}
                          {personHours}
                        </Label>
                      </td>
                    );
                  },
                )}
              </tr>
            );
          })}
        </>
      )}
    </>
  );
};

function MidtermCapacityTable(props: { matrixType: string, height: number }) {
  const midtermCapacityData: any = useSelector((state: any) => state.vp.midtermCapacityData);
  const [isChecked, setIsChecked] = React.useState(true);
  const [view, setView] = React.useState<ViewMode>(ViewMode.Day);
  const [weeks, setWeeks] = React.useState<any[]>([]);
  const [resourceData, setResourceData] = React.useState<any>([]);
  const [weeklyGroupedDatesArray, setWeeklyGroupedDatesArray] = React.useState<any>([]);
  const {
    selectedBuInCuProjectPlanning,
    projectLevelReloadRequired,
    productTaxonomyReloadRequired,
  } = useSelector((state: any) => state.vp);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const matrixTableRef = useRef(null);

  const optionsForMePP: IComboBoxOption[] = [
    {
      key: ViewMode.Day,
      text: t('day'),
    },
    {
      key: ViewMode.Week,
      text: t('week'),
    },
    {
      key: ViewMode.Month,
      text: t('Month'),
    },
  ];

  const onViewModeChange = (viewMode: ViewMode) => {
    setView(viewMode);
  };

  const filterData = {
    title: null,
    contractingUnit: store.getState().uprince?.contractingUinit,
    businessUnit: getBuGroups(),
  };


  const lastWeekStartDate = moment().subtract(1, 'weeks').startOf('week').format('MM/DD/YYYY');
  const nextTwoWeeksLastDay = moment().add(2, 'weeks').endOf('week').format('MM/DD/YYYY');
  const lastMonthStartDate = moment().subtract(1, 'months').startOf('month').format('MM/DD/YYYY');
  const nextTwoMonthsLastDay = moment().add(2, 'months').endOf('month').format('MM/DD/YYYY');

  const datePayload = {
    startDate: view == ViewMode.Month ? lastMonthStartDate : lastWeekStartDate,
    endDate: view == ViewMode.Month ? nextTwoMonthsLastDay : nextTwoWeeksLastDay,
    type: view,
  };


  useEffect(() => {
    dispatch(getMidtermCapacityData(datePayload));

    return () => {
    };
  }, [props.matrixType, view, selectedBuInCuProjectPlanning, projectLevelReloadRequired, productTaxonomyReloadRequired]);

  useEffect(() => {
    if (midtermCapacityData) {
      let { week, resource, days } = midtermCapacityData;

      // Week and Month data comes with null days array
      if (!days) {
        days = week;
      }

      // Formatting Unassigned and Absent data
      const unassignedData = {
        projectManager: 'Unassigned',
        children: [
          { project: 'Unassigned', ...resource?.unAssigned[0] },
          { project: 'Absent', ...resource?.absent[0] }],
      };

      const combinedResourceData = [unassignedData, ...resource?.projectManager];
      setResourceData(combinedResourceData);

      const groupedArray = [];
      for (let i = 0; i < week?.length; i++) {
        const startIdx = i * 7;
        const endIdx = startIdx + 7;
        const weekDays = days?.slice(startIdx, endIdx);

        groupedArray.push({
          week: week[i],
          days: weekDays,
        });
      }

      setWeeklyGroupedDatesArray(groupedArray);
      setWeeks(week ?? days);


      // Scrolling table to current week
      // const currentWeekWithW = `W${moment().week()} `;
      // const currentWeekColumnIndex = week.findIndex((element: string | string[]) => element.includes(currentWeekWithW));
      //
      // const table: any = matrixTableRef.current;
      // const scrollValue = (currentWeekColumnIndex - 1) * 260;
      //
      // Promise.all([
      //   table.scrollLeft += -(52 * 260),
      // ]).then(() => {
      //   table.scrollLeft += scrollValue;
      // });
    }
  }, [midtermCapacityData]);


  return (
    <div
      className={'matrixTable'}
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <ViewModeContext.Provider
        value={{
          onViewModeChange: (viewMode: ViewMode) => onViewModeChange(viewMode),
          onViewListChange: (isCheck: boolean) => setIsChecked(isCheck),
          isCheck: isChecked,
        }}
      >
        <TableExpandProvider>
          <ViewSwitcher
            onViewModeChange={(viewMode: ViewMode) => {
              onViewModeChange(viewMode);
            }}
            onViewListChange={setIsChecked}
            isChecked={isChecked}
            renderFilterComponent={() => <Label></Label>}
            option={optionsForMePP}
          />
          <div ref={matrixTableRef} id={'matrix-table-table'}
               style={{ width: '89vw', height: props.height - 96, overflow: 'overlay' }}>
            <table
              style={{
                borderCollapse: 'collapse',
                width: 'min-content',
                padding: 20,
                tableLayout: 'auto',
              }}
            >
              <thead>
              {view == ViewMode.Day && <tr style={{position: 'sticky', top: 0, zIndex: 10}}>
                <th style={{ minWidth: '28vw', height: ROW_HEIGHT, padding: 0 }}>
                  <div id={'matrix-table-table-header'} style={{
                    width: '100%',
                    height: 'inherit',
                    background: 'white',
                    borderRight: '1px solid #BFC9CA',
                    marginTop: -1,
                  }}></div>
                </th>
                {weeks && weeks?.map((weekNumber: any, index ) => {
                  return <th key={index} colSpan={7} style={{
                    background: 'white',
                    border: '1px solid #BFC9CA',
                    verticalAlign: 'middle',
                  }}>
                    <Label style={{ fontSize: 14 }}>
                      {weekNumber}
                    </Label>
                  </th>;
                })}
              </tr>}

              <tr style={{
                position: 'sticky',
                top: view == ViewMode.Day ? ROW_HEIGHT : undefined,
                height: 2,
                border: '1px solid #BFC9CA',
                zIndex: 10,
              }}>
                <th style={{ minWidth: '28vw', height: ROW_HEIGHT, padding: 0 }}>
                  <div id={'matrix-table-table-header'} style={{ width: '100%', height: 'inherit', background: 'white', borderRight: '1px solid #BFC9CA' }}></div>
                </th>
                {
                  weeklyGroupedDatesArray && weeklyGroupedDatesArray?.map((e: any, index: number) => {
                    return (
                      <th key={index} colSpan={7} style={{
                        // background: '#724519',
                        // border: '1px solid #BFC9CA',
                        verticalAlign: 'middle',
                        padding: 0,
                        height: 'inherit',
                      }}>
                        {Object.entries(e.days).map(([key, dateAndName], index) => (
                          <th
                            key={index}
                            style={{
                              background: index === 5 || index === 6 ? weekendColor : 'white',
                              minWidth: view == ViewMode.Day ? '3.8vw' : '14.3vw',
                              width: '8vw',
                              verticalAlign: 'middle',
                              border: '1px solid #BFC9CA',
                              padding: 0,
                              height: ROW_HEIGHT,
                            }}
                          >
                            {view == ViewMode.Day ?
                              <>
                                <Label style={{ fontSize: 12, padding: 0 }}>
                                  {dateAndName && moment(dateAndName, 'ddd-MMM DD').format('ddd')}
                                </Label>
                                <Label style={{ fontSize: 12, padding: 0 }}>
                                  {dateAndName && moment(dateAndName).format('MMM DD')}
                                </Label>
                              </> :
                              <Label style={{ fontSize: 14 }}>
                                {dateAndName}
                              </Label>}
                          </th>
                        ))}

                      </th>
                    );
                  })
                }

              </tr>
              </thead>
              <tbody>
              {/*Data table*/}
              {resourceData && resourceData?.map((e: any, index: number) => {
                return <TableRow key={index} index={index} projectManagerName={e?.projectManager} mainValues={e}
                                 weeks={view == ViewMode.Day ? weeks : { weeks }} />;
              })}
              </tbody>
            </table>
          </div>
        </TableExpandProvider>
      </ViewModeContext.Provider>
    </div>
  );
}

export default MidtermCapacityTable;
