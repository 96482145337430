import {
  ContextualMenuItemType,
  FontWeights,
  getTheme,
  Icon,
  IIconProps,
  IPivotItemProps,
  mergeStyleSets,
  Pivot,
  PivotItem,
  PrimaryButton,
} from '@fluentui/react';

import React from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router';
import { iconColors, uPrinceTheme } from '../../../theme';
import history from '../../history';
import { messageService } from '../../services/messageService';
import { AccessLevel, PROJECT_FEATURS } from '../../types/permissions';
import MenuCard from '../menuCard/menu/component';
import { getProject, hasPermissions, isPlanBoards } from '../util';
import './navBar.css';
import ENV from '../../../env.json';
import i18n from '../../../i18n';
import { saveMyDayPlanningStateAttr } from '../../reducers/myDayPlanningReducer';
import { connect } from 'react-redux';

const theme = getTheme();

// const pivotStyle = { root: { float: 'left', display: 'flex', flexWrap: 'wrap', selectors: { '& .is-selected i': { color: iconColors.iconActiveColor } } }, icon: { color: uPrinceTheme.palette.themePrimary }, linkIsSelected: { selectors: { ':before': { marginBottom: '0px' } } } };
const pivotStyle = {
  root: {
    maxWidth: '90vw',
    float: 'left',
    selectors: { '& .is-selected i': { color: iconColors.iconActiveColor } },
  },
  icon: { color: uPrinceTheme.palette.themePrimary },
  linkIsSelected: { selectors: { ':before': { marginBottom: '0px' } } },
};

class ProjectNavBar extends React.Component<any, any> {
  subscription: any;
  addWhiteIconButtonStyles = {
    root: { color: uPrinceTheme.palette.white },
    rootHovered: { color: theme.palette.neutralDark },
  };
  addIconWhite: IIconProps = {
    iconName: 'Add',
    styles: this.addWhiteIconButtonStyles,
  };
  styles = mergeStyleSets({
    buttonArea: {
      textAlign: 'center',
      margin: '0 0px',
      minWidth: 32,
      height: 32,
    },
    callout: { maxWidth: 300 },
    header: { padding: '18px 24px 12px' },
    title: [
      uPrinceTheme.fonts.xLarge,
      {
        margin: 0,
        fontWeight: FontWeights.semilight,
      },
    ],
    inner: {
      height: '100%',
      padding: '0 24px 20px',
    },
    actions: {
      position: 'relative',
      marginTop: 20,
      width: '100%',
      whiteSpace: 'nowrap',
    },
    subtext: [
      uPrinceTheme.fonts.small,
      {
        margin: 0,
        fontWeight: FontWeights.semilight,
      },
    ],
    link: [
      uPrinceTheme.fonts.medium,
      { color: uPrinceTheme.palette.neutralPrimary },
    ],
  });

  state = {
    redirect: false,
    redirectTo: '',
    isCalloutVisible: false,
    showNewButton: false,
  };

  toggleIsCalloutVisible = () => {
    this.setState({ isCalloutVisible: !this.state.isCalloutVisible });
  };

  handelRedirect = (item: any) => {
    history.push(item);
  };

  componentDidMount() {
    this.subscription = messageService.getMessage().subscribe((data: any) => {
      if (data) {
        if (data && data.data && data.data.shortCutPaneExpanded) {
          this.setState({ showNewButton: true });
        } else if (
          data.data.hasOwnProperty('shortCutPaneExpanded') &&
          !data.data.shortCutPaneExpanded
        ) {
          this.setState({ showNewButton: false });
        }
      }
    });
  }

  componentWillUnmount() {
    this.subscription.unsubscribe();
  }

  menuProps = () => ({
    shouldFocusOnMount: true,
    items: [
      {
        key: 'newItem',
        text: 'New',
        onClick: () => {},
      },
      { key: 'divider_1', itemType: ContextualMenuItemType.Divider },
      {
        key: 'rename',
        text: 'Rename',
        onClick: () => {},
      },
      {
        key: 'edit',
        text: 'Edit',
        onClick: () => {},
      },
      {
        key: 'properties',
        text: 'Properties',
        onClick: () => {},
      },
    ],
  });

  getColor = (link?: IPivotItemProps) => {
    switch (link?.headerText) {
      case `${i18n.t('projectDefinition')}`:
        return ENV.FEATURE_ICON_COLORS.PROJECT_DEF;
      case `${i18n.t('projectBreakdownStructure')}`:
        return ENV.FEATURE_ICON_COLORS.PBS;
      case `${i18n.t('corporateProductCatalogue')}`:
        return ENV.FEATURE_ICON_COLORS.CPC;
      case `${i18n.t('billOfResources')}`:
        return ENV.FEATURE_ICON_COLORS.BOR;
      case `${i18n.t('purchaseOrder')}`:
        return ENV.FEATURE_ICON_COLORS.PO;
      case `${i18n.t('pojectMolecule')}`:
        return ENV.FEATURE_ICON_COLORS.PMOL;
      case `${i18n.t('progressStatement')}`:
        return ENV.FEATURE_ICON_COLORS.PS;
      case `${i18n.t('invoice')}`:
        return ENV.FEATURE_ICON_COLORS.Invoice;
      case `${i18n.t('stock')}`:
        return ENV.FEATURE_ICON_COLORS.STOCK;
      case `${i18n.t('dayPlanning')}`:
        return ENV.FEATURE_ICON_COLORS.DP;
      case `${i18n.t('projectPlaning')}`:
        return ENV.FEATURE_ICON_COLORS.VP;
      case `${i18n.t('quality')}`:
        return ENV.FEATURE_ICON_COLORS.QUALITY;
      case `${i18n.t('risk')}`:
        return ENV.FEATURE_ICON_COLORS.RISK;
      case `${i18n.t('instruction')}`:
        return ENV.FEATURE_ICON_COLORS.Instruction;
      case `${i18n.t('contract')}`:
        return ENV.FEATURE_ICON_COLORS.CONTRACT;
      case `${i18n.t('dayPlanningNew')}`:
        return ENV.FEATURE_ICON_COLORS.MYDP;
      case `${i18n.t('projectDayPlanning')}`:
        return ENV.FEATURE_ICON_COLORS.PDP;
      default:
        return ENV.PRIMARY_COLOR;
    }
  };

  customRenderer = (
    link?: IPivotItemProps,
    defaultRenderer?: (link?: IPivotItemProps) => JSX.Element | null
  ): JSX.Element | null => {
    if (!link || !defaultRenderer) {
      return null;
    }

    return (
      <span style={{ flex: '0 1 100%' }}>
        <Icon
          iconName={link?.itemIcon}
          style={{ color: this.getColor(link), marginRight: 4 }}
        />
        {defaultRenderer({ ...link, itemIcon: undefined })}
      </span>
    );
  };

  render() {
    const { t } = this.props;
    let cu = '';
    if (
      window.location.pathname &&
      window.location.pathname.split('/').length > 0
    ) {
      cu = window.location.pathname.split('/')[2];
    }

    const isCustomerPortalUser = hasPermissions(
      this.props.currentUser?.groups
    ).isCustomerPortalUser;

    return (
      <div className="navBar">
        <div className={this.styles.buttonArea}>
          <Icon
            iconName="ViewAll"
            className="navicon"
            onClick={() => {
              if (!ENV.DISABLE_MENU_CARD) {
                this.toggleIsCalloutVisible();
              }
            }}
          />
        </div>
        {this.state.showNewButton && ENV.ENABLE_NAV_BAR_BUTTON && (
          <div>
            <PrimaryButton
              iconProps={this.addIconWhite}
              text={t('new')}
              menuProps={this.menuProps()}
              style={{
                marginTop: 5,
                marginBottom: 5,
                marginRight: 35,
                height: 30,
              }}
              onClick={() => {}}
            />
          </div>
        )}
        {!ENV.ENABLE_NAV_BAR_BUTTON && (
          <div
            style={{
              marginTop: 5,
              marginBottom: 5,
              marginRight: 140,
              height: 30,
            }}
          ></div>
        )}
        <div>
          <Pivot
            selectedKey={this.props.selectedKey}
            styles={pivotStyle}
            overflowBehavior={'menu'}
            onLinkClick={(item) => {
              item ? this.handelRedirect(item.props.itemKey) : null;
              if (!isPlanBoards()) {
                this.props.saveMyDayPlanningStateAttr(
                  'selectedDate',
                  undefined
                );
              }
            }}
          >
            {!isCustomerPortalUser &&
              ENV.PROJECT_FEATURES.includes(PROJECT_FEATURS.PROJECT_DEF) && (
                <PivotItem
                  itemKey={`/CU/${cu}/project/${getProject()}/project-definition`}
                  headerText={t('projectDefinition')}
                  itemIcon="ProjectLogoInverse"
                  onRenderItemLink={this.customRenderer}
                ></PivotItem>
              )}
            {ENV.PROJECT_FEATURES.includes(PROJECT_FEATURS.VP) && (
              <PivotItem
                itemKey={
                  isCustomerPortalUser
                    ? `/CU/${cu}/project/${getProject()}/project-planning?from-plan-board=true`
                    : `/CU/${cu}/project/${getProject()}/project-planning`
                }
                headerText={t('projectPlaning')}
                itemIcon="StackedBarChart"
                onRenderItemLink={this.customRenderer}
              ></PivotItem>
            )}
            {/*{ENV.PROJECT_FEATURES.includes(PROJECT_FEATURS.DP) && (*/}
            {/*  <PivotItem*/}
            {/*    itemKey={`/CU/${cu}/project/${getProject()}/day-planning`}*/}
            {/*    headerText={t('dayPlanning')}*/}
            {/*    itemIcon="BarChartVerticalEdit"*/}
            {/*    onRenderItemLink={this.customRenderer}*/}
            {/*  ></PivotItem>*/}
            {/*)}*/}
            {!isCustomerPortalUser &&
              ENV.PROJECT_FEATURES.includes(PROJECT_FEATURS.PBS) && (
                <PivotItem
                  itemKey={`/CU/${cu}/project/${getProject()}/product/pbs`}
                  headerText={t('projectBreakdownStructure')}
                  itemIcon="Org"
                  onRenderItemLink={this.customRenderer}
                ></PivotItem>
              )}
            {!isCustomerPortalUser &&
              ENV.PROJECT_FEATURES.includes(PROJECT_FEATURS.CPC) && (
                <PivotItem
                  itemKey={`/CU/${cu}/project/${getProject()}/product-catalogue`}
                  headerText={t('corporateProductCatalogue')}
                  itemIcon="ProductCatalog"
                  onRenderItemLink={this.customRenderer}
                ></PivotItem>
              )}
            {!isCustomerPortalUser &&
              ENV.PROJECT_FEATURES.includes(PROJECT_FEATURS.BOR) && (
                <PivotItem
                  itemKey={`/CU/${cu}/project/${getProject()}/bill-Of-resources`}
                  headerText={t('billOfResources')}
                  itemIcon="Calculator"
                  onRenderItemLink={this.customRenderer}
                ></PivotItem>
              )}
            {!isCustomerPortalUser &&
              hasPermissions(this.props.currentUser?.groups).isProjectManager &&
              ENV.PROJECT_FEATURES.includes(PROJECT_FEATURS.PO) && (
                <PivotItem
                  itemKey={`/CU/${cu}/project/${getProject()}/purchase-order`}
                  headerText={t('purchaseOrder')}
                  itemIcon="ActivateOrders"
                  onRenderItemLink={this.customRenderer}
                ></PivotItem>
              )}
            {!isCustomerPortalUser &&
              ENV.PROJECT_FEATURES.includes(PROJECT_FEATURS.PMOL) && (
                <PivotItem
                  itemKey={`/CU/${cu}/project/${getProject()}/project-molecule`}
                  headerText={t('pojectMolecule')}
                  itemIcon="EngineeringGroup"
                  onRenderItemLink={this.customRenderer}
                ></PivotItem>
              )}
            {!isCustomerPortalUser &&
              hasPermissions(this.props.currentUser?.groups).isProjectManager &&
              ENV.PROJECT_FEATURES.includes(PROJECT_FEATURS.PMOL) && (
                <PivotItem
                  itemKey={`/CU/${cu}/project/${getProject()}/progress-statement`}
                  headerText={t('progressStatement')}
                  itemIcon="TableGroup"
                  onRenderItemLink={this.customRenderer}
                ></PivotItem>
              )}
            {!isCustomerPortalUser &&
              hasPermissions(this.props.currentUser?.groups).isProjectManager &&
              ENV.PROJECT_FEATURES.includes(PROJECT_FEATURS.INVOICE) && (
                <PivotItem
                  itemKey={`/CU/${cu}/project/${getProject()}/invoice`}
                  headerText={t('invoice')}
                  itemIcon="M365InvoicingLogo"
                  onRenderItemLink={this.customRenderer}
                ></PivotItem>
              )}
            {/* {
                            hasPermissions(this.props.currentUser?.groups).isProjectManager &&

                            <PivotItem itemKey={ `/CU/${cu}/project/${getProject()}/work-flow` } headerText={ t('workflow') } itemIcon="M365InvoicingLogo"></PivotItem>
                        } */}
            {!isCustomerPortalUser &&
              ENV.PROJECT_FEATURES.includes(PROJECT_FEATURS.STOCK) && (
                <PivotItem
                  itemKey={`/CU/${cu}/project/${getProject()}/stock`}
                  headerText={t('stock')}
                  itemIcon="Archive"
                  onRenderItemLink={this.customRenderer}
                ></PivotItem>
              )}
            {/* {ENV.PROJECT_FEATURES.includes(PROJECT_FEATURS.DP_NEW) && (
              <PivotItem
                itemKey={`/CU/${cu}/project/${getProject()}/day-planning-new`}
                headerText={t('dayPlanningNew')}
                itemIcon="BarChartVerticalEdit"
              ></PivotItem>
            )} */}

            {/* <PivotItem
                            itemKey={ `/CU/${cu}/project/${getProject()}/milestone` }
                            headerText={ t('milestones') }
                            itemIcon="ActivateOrders"
                        ></PivotItem> */}

            {!isCustomerPortalUser &&
              hasPermissions(this.props.currentUser?.groups)
                .isProjectTenderManager &&
              ENV.PROJECT_FEATURES.includes(PROJECT_FEATURS.CONTRACT) && (
                <PivotItem
                  itemKey={`/CU/${cu}/project/${getProject()}/contract`}
                  headerText={t('contract')}
                  itemIcon="AccountManagement"
                  onRenderItemLink={this.customRenderer}
                ></PivotItem>
              )}
            {!isCustomerPortalUser &&
              hasPermissions(this.props.currentUser?.groups)
                .isProjectTeamManager && (
                <PivotItem
                  itemKey={`/CU/${cu}/project/${getProject()}/quality`}
                  headerText={t('quality')}
                  itemIcon="Certificate"
                  onRenderItemLink={this.customRenderer}
                ></PivotItem>
              )}
            {!isCustomerPortalUser &&
              hasPermissions(this.props.currentUser?.groups)
                .isProjectTeamManager && (
                <PivotItem
                  itemKey={`/CU/${cu}/project/${getProject()}/risk-register`}
                  headerText={t('risk')}
                  itemIcon="Error"
                  onRenderItemLink={this.customRenderer}
                ></PivotItem>
              )}
            {!isCustomerPortalUser &&
              hasPermissions(this.props.currentUser?.groups)
                .isProjectTeamManager && (
                <PivotItem
                  itemKey={`/CU/${cu}/project/${getProject()}/instruction-register`}
                  headerText={t('instruction')}
                  itemIcon="AccountActivity"
                  onRenderItemLink={this.customRenderer}
                ></PivotItem>
              )}
            {/* {hasPermissions(this.props.currentUser?.groups)
              .isProjectTeamManager && ( */}
            {!isCustomerPortalUser && (
              <PivotItem
                itemKey={`/CU/${cu}/project/${getProject()}/wbs`}
                headerText={t('wbs')}
                itemIcon="ExternalTFVC"
                onRenderItemLink={this.customRenderer}
              ></PivotItem>
            )}
            {/* )} */}
            {!isCustomerPortalUser &&
              hasPermissions(this.props.currentUser?.groups)
                .isProjectTeamManager && (
                <PivotItem
                  itemKey={`/CU/${cu}/project/${getProject()}/day-planning-new`}
                  headerText={t('teamPlanboard')}
                  itemIcon="ColumnRightTwoThirdsEdit"
                  onRenderItemLink={this.customRenderer}
                ></PivotItem>
              )}
            {!isCustomerPortalUser &&
              hasPermissions(this.props.currentUser?.groups)
                .isProjectTeamManager && (
                <PivotItem
                  itemKey={`/CU/${cu}/project/${getProject()}/project-day-planning`}
                  headerText={t('projectPlanboard')}
                  itemIcon="QuadColumn"
                  onRenderItemLink={this.customRenderer}
                ></PivotItem>
              )}
          </Pivot>
        </div>
        <MenuCard
          isMenuCardVisible={this.state.isCalloutVisible}
          className={this.styles.callout}
          target={this.styles.buttonArea}
          onDissmissed={() => this.toggleIsCalloutVisible()}
          accessLevel={AccessLevel.PROJECT}
          currentUser={this.props.currentUser}
          organization={this.props.organization}
        />
      </div>
    );
  }
}

//export default withTranslation()(withRouter(ProjectNavBar));

const mapStateToProps = (state: any) => {
  return { organisation: state.uprince.organisation };
};

const mapDispatchToProps = {
  saveMyDayPlanningStateAttr,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(ProjectNavBar)));
