import React, {useEffect, useState} from 'react';
import DefaultCustomModal from "../../../../shared/customeModal/defaultCustomModal";
import {useDispatch, useSelector} from "react-redux";
import moment from "moment";
import {useTranslation} from "react-i18next";
import {
    createMyCalendarNewPmol,
    readMyCalendarPbsTaxonomy,
    saveDayPlanningStateAttr
} from "../../../../reducers/dayPlanningReducer";
import {Field, Form} from 'react-final-form';
import _ from 'lodash';
import { Checkbox, DefaultButton, Label, MaskedTextField, mergeStyleSets, PrimaryButton, Stack } from '@fluentui/react';
import {DatePickerAdapter, DropDownAdaptater, TextFieldFF} from "../../../../shared/fluentFinalForm";
import {stackItemStyles, stackStyles, stackTokens} from '../../../../shared/customStyle/fluentStyles';
import {store} from "../../../../../index";
import client from "../../../../api";
import PeoplePicker from "../../../../shared/fluentFinalForm/PeoplePicker";
import {messageService} from "../../../../services/messageService";
import {LOAD_LABOUR_LIST_MY_ENV_EP} from "../../../../shared/endpoints";
import {
    formatDateTimeToISOString,
    getBreadcrumbItemFromData,
    getProject,
    isMyEnvironment,
    isPlanBoards,
    maskFormat,
} from '../../../../shared/util';
import ProductTaxonomyField from "../../../ProjectBreakdownStructure/taxonomy/productTaxonomy";
import MultiLineTextFieldWrapper from "../../../../shared/fluentFinalForm/MultilineTextField";
import {DEFAULT_FOREMAN, PMOL_ACTIVITY_TYPES} from "../../../../types/projectMolecule";
import {getProjectDefinition} from "../../../../reducers/projectReducer";
import {getReadLabourByPbsProduct} from "../../../../reducers/projectReducer";
import {createForm} from "final-form";
import CustomDropdown from "../../../../shared/customDropdown/customDropdown";
import SearchableDropdown from "../../../../shared/searchableDropdown/searchableDropdown";
import {foremanId} from "../../../../types/myDayPlanning";
import CbcField from './CbcField';
import CbcGrid from './cbc/CbcGrid';

interface Props {
    fCode: string | null;
}

export default function CreatePmolModal(props: Props) {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {fCode} = props;

    const {
        myCalendarSlotData, // This is used in plan boards calenders too
        myCalenderPbsTaxonomy,
        showMyCalenderPbsTaxonomyProgressBar,
        dayPlanningDate,
        resourceSelectorData,
    } = useSelector((state: any) => state.dplan);
    const {selectedDate} = useSelector((state: any) => state.mydPlan);
    const {pmolType} = useSelector((state: any) => state.pmol);
    const {projectState}: any = useSelector((projectState: any) => projectState.project);
    const {selectedCu} = useSelector((state: any) => state.projectDPlan);
    const selectedProject = useSelector((state: any) => state?.project?.selectedProject);

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [myCalendarSlotDataInitState, setMyCalendarSlotDataInitState] = useState<any>({});
    const [selectedProjectState, setSelectedProjectState] = useState<any>(null);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [labourOptions, setLabourOptions] = useState<any>({});
    const foremanData = resourceSelectorData?.team.filter((i: any) => i?.roleId === foremanId);

    const [laborResouceItemByProducts, setLaborResouceItemByProducts] = useState<any>(null);
    useEffect(() => {
        if (isOpen) {
            if (myCalendarSlotData.planBoardType === 'project' ) {
                projectSelector(myCalendarSlotData.resourceId);
            }
            if(fCode === "day-planning-new" && getProject()) {
                projectSelector(getProject());
            }
        }
    }, [isOpen]);

    useEffect(() => {

        const subscription = messageService.getMessage().subscribe((data: any) => {
            if (data) {
                if (data.data.openTaxonomyModal) {

                }
            }
        });

        return () => {
            dispatch(saveDayPlanningStateAttr("resourceSelectorData", null));
            subscription.unsubscribe();
        }
    }, []);

    useEffect(() => {
        // console.log('myCalendarSlotData>', myCalendarSlotData)
        if (myCalendarSlotData && !_.isEmpty(myCalendarSlotData) && myCalendarSlotData?.action === "select") {
            const startTime = moment(myCalendarSlotData?.start, "hh:mm:ss a");
            const endTime = moment(myCalendarSlotData?.end, "hh:mm:ss a");
            // const mins = moment.utc(moment(endTime, "HH:mm:ss").diff(moment(startTime, "HH:mm:ss"))).format("mm")
            // const totalHours = endTime.diff(startTime, 'hours') + " Hrs and " + mins + " Mns";
            const duration = moment.duration(endTime.diff(startTime)).asHours();
            const cal = moment.utc(moment(endTime, "HH:mm:ss").diff(moment(startTime, "HH:mm:ss"))).format("HH:mm")

            setMyCalendarSlotDataInitState({
                startTime: moment(myCalendarSlotData?.start).format('HH:mm'),
                endTime: moment(myCalendarSlotData?.end).format('HH:mm'),
                calcTime: cal,
                durationF: duration,
            })

            setIsOpen(true);
        }
    }, [myCalendarSlotData]);

    const projectSelector = (inputValue: any) => {
        Promise.all([
            dispatch(getProjectDefinition(inputValue?.sequenceCode ?? inputValue)),
            dispatch(readMyCalendarPbsTaxonomy(inputValue?.sequenceCode ?? inputValue)),
        ]).then((res: any) => {
            if (!_.isEmpty(res) && res[0]?.payload?.data?.result) {
                setSelectedProjectState(
                    {
                        projectId: res[0].payload.data.result?.id,
                        sequenceCode: res[0].payload.data.result?.sequenceCode,
                        value: res[0].payload.data.result?.id,
                        label: res[0].payload.data.result?.title,
                    },
                );

                formRef.current.mutators.setProjectName({
                    value: res[0].payload.data.result.id,
                    label: res[0].payload.data.result.title,
                });

                const selectedItem = {
                    value: res[0].payload.data.result.id,
                    label: res[0].payload.data.result.title,
                    sequenceCode: res[0].payload.data.result.sequenceCode
                };
                
                formRef.current.mutators.setSlectedProject(selectedItem);
                formRef.current.mutators.setCiaw(res[0].payload.data.result.ciawCode);
                formRef.current.mutators.setProjectStatus(res[0].payload.data.result.projectStatus);
                formRef.current.mutators.setPMId(res[0].payload.data.result.projectManagerId);
                formRef.current.mutators.setPMName(res[0].payload.data.result.projectManagerName);
                formRef.current.mutators.setCustomerId(res[0].payload.data.result.customerId);
                formRef.current.mutators.setCustomerName(res[0].payload.data.result.customerName);
                formRef.current.mutators.setSiteManagerId(res[0].payload.data.result.siteManagerId);
                formRef.current.mutators.setSiteManagerName(res[0].payload.data.result.siteManagerName);
             
                
            }
        });
    };
    let getResponseOfReadLabourByPbsProductResult = [];
    const getResponseOfReadLabourByPbsProduct = (inputValue: any) => {
        Promise.all([
            dispatch(getReadLabourByPbsProduct(inputValue)),
        ]).then((res: any) => {
            
            if(res !=null && res[0].payload.data.result.length > 0)
            {
                updateLabourOptions(res[0].payload.data.result);
                var resultSize=res[0].payload.data.result.length;
                formRef.current.mutators.setPMOLabourResourceItem(res[0].payload.data.result[0].cpcKey);
                formRef.current.mutators.setPMOLabourResourceItemLabel(res[0].payload.data.result[0].cpcText);
                formRef.current.mutators.setLabourResourceQuantity(res[0].payload.data.result[0].quantity);
            }  
            else
            {
                formRef.current.mutators.setPMOLabourResourceItem(DEFAULT_FOREMAN.ID);
                formRef.current.mutators.setPMOLabourResourceItemLabel(DEFAULT_FOREMAN.RESOURCE_TITLE);
                formRef.current.mutators.setLabourResourceQuantity(0);
            } 
        });
    };


    const formDataInit = {
        projectName: null,
        ciawSiteCode: "",
        projectStatus: null,
        projectManagerId: null,
        projectManagerName: null,
        projectCustomerId: null,
        projectCustomerName: null,
        projectSiteManagerId: null,
        projectSiteManagerName: null,
        pbsTaxonomy: "",
        pmolName: "",
        pmolType: PMOL_ACTIVITY_TYPES.WORK,
        pmolLabourResourceItem:"",
        labourResourceItemLabel: "",
        pmolLabourResourceQuantity: "",
        pmolDate: moment(isPlanBoards() ? selectedDate : dayPlanningDate).format('YYYY-MM-DD'),
        pmolStartTime: myCalendarSlotDataInitState?.startTime,
        pmolEndTime: myCalendarSlotDataInitState?.endTime,
        pmolFinish: false,
        pmolCalculatedTime: myCalendarSlotDataInitState?.calcTime,
        pmolComments: "",
        pmolTimeDuration: myCalendarSlotDataInitState?.durationF,
        foreman: null,
        foremanName: null,
        teamId: resourceSelectorData ? resourceSelectorData?.teamId : null,
    }

    const getInitialFormValues = (code: string | null) => {
        if (code === "day-planning-new") {
            return {
                ...formDataInit,
                foreman: !_.isEmpty(foremanData) ? foremanData[0].id : resourceSelectorData?.team[0]?.id,
                foremanName: !_.isEmpty(foremanData) ? foremanData[0].name : resourceSelectorData?.team[0]?.name,
            }
        } else {
            return {
                ...formDataInit,
                foreman: resourceSelectorData ? resourceSelectorData?.team[0]?.id : null,
                foremanName: resourceSelectorData ? resourceSelectorData?.team[0]?.name : null,
            }
        }
    }

    const onSubmit = async (values: any) => {
        setIsSubmitting(true);
        if (values) {
            // console.log('values >', values);
            const data = {
                name: values?.pmolName,
                executionDate: values?.pmolDate,
                comment: values?.pmolComments,
                productId: values?.pbsTaxonomy,
                isFinished: values?.pmolFinish,
                executionStartTime: values?.pmolStartTime,
                executionEndTime: values?.pmolEndTime,
                labourCpcId: values?.pmolLabourResourceItem,
                mouId: values?.mouId,
                consumedQuantity: "0",  //NOTE: keep this "0"
                plannedQuantity: values?.pmolTimeDuration,
                projectSequenceId: selectedProjectState?.sequenceCode,
                foremanId: values?.foreman,
                pmolTypeId: values?.pmolType,
                teamId: values?.teamId,
                cbc: values?.cbc,
            }
            await Promise.all([
                dispatch(createMyCalendarNewPmol(data))
            ]).then((res: any) => {
                if (res[0]?.payload?.data?.result) {
                    handleClose();
                }
            });
        }
    };

    const formRef = React.useRef(createForm({onSubmit: onSubmit}));

    const handleClose = () => {
        dispatch(saveDayPlanningStateAttr("myCalendarSlotData", {}));
        setIsOpen(false);
        setIsSubmitting(false);
    }

    const onRenderFooter = () => {
        return <div className={'footer-btn'}>
            <DefaultButton onClick={handleClose}>
                {t('cancel')}
            </DefaultButton>
            <PrimaryButton type="submit" form={"create-pmol-form"}>
                {t('save')}
            </PrimaryButton>
        </div>
    }

    const projectOptions = (inputValue: any) =>
        new Promise((resolve) => {
            resolve(getProjectByName(inputValue));
        });

    const getProjectByName = async (name: string) => {
        let projects: any = [];
        const cu = store.getState()?.uprince?.userCu ? store.getState()?.uprince?.userCu?.sequenceCode : null;
        const bu = store.getState()?.projectDPlan?.selectedBu ? store.getState()?.projectDPlan?.selectedBu : null;

        if (name.length >= 2 || name === '') {
            const response = await client.post(
                isPlanBoards() ? 'ContractingUnitTenants/GetProjectsByUserProjectFilter' : 'MyCalender/MyCalenderProjectFlter', {
                    title: name,
                    projectTypeId: null,
                    managementLevelId: null,
                    toleranceStateId: null,
                    toleranceState: null,
                    contractingUnit: cu ? cu : null,
                    buId: bu ? bu : null,
                    sorter: {
                        attribute: null,
                        order: null,
                    },
                });
                const length = response.data.result.length; 
                if(length >0)
                {
                    projectSelector(response.data.result[length-1].sequenceCode);
                }
            return formatProjectResponse(response);
        }
        return projects;
    };

    const formatProjectResponse = (response: any) => {
        let options: { value: string; label: string }[] = [];
        if (response && response.data && response.data.result) {
            const data = response.data.result;

            if (data && _.isArray(data)) {
                options = data.map((item: any) => {
                    return {
                        ...item,
                        value: item.projectId ?? item.projectDefinitionId,
                        label: item.projectTitle ?? item.title,
                    };
                });
            }
            return options;
        }
    };

    const labourResourceOptions = (inputValue: any) =>
        new Promise((resolve) => {
            if (getResponseOfReadLabourByPbsProductResult.length > 0) {
                alert("happy");
                }
            resolve(getLabourItemByName(inputValue));

        });
        
    const getLabourItemByName = async (name: string) => {
        // let filter = {
        //     cu: selectedCu?.sequenceCode,
        //     title: name,
        //     projectSequenceId: selectedProjectState?.sequenceCode
        // };

        // if (name) {
        //     const response = await client.post(LOAD_LABOUR_LIST_MY_ENV_EP, filter);
        //     return formatLabourResponse(response);
        // }
        if(name!=null && name!=""){
            const filteredOptions = labourOptions.filter((option: { cpcText: string; }) =>
                option.cpcText.toLowerCase().includes(name.toLowerCase())
            );
            return formatLabourResponse(filteredOptions);
        }
        else{
            return formatLabourResponse(labourOptions);
        } 
    };

    const updateLabourOptions = (data:any) => {
        setLabourOptions(data);
      };

    const formatLabourResponse = (response: any) => {
        let data: { value: string; label: string }[] = [];
        // if (response && response.data && _.isArray(response.data.result)) {
        //     data = response.data.result.map((item: any) => {
        //         return {
        //             ...item,
        //             value: item.id,
        //             label: item.title,
        //         };
        //     });
        // }
        if (response && response.length >0) {
            data = response.map((item: any) => {
            return {
                ...item,
                value: item.cpcKey,
                label: item.cpcText,
            };
        });
    }
        return data;
    };

    const setCalcTime = (startTime: any, endTime: any): void => {
        const start = moment(startTime, "hh:mm:ss a");
        const end = moment(endTime, "hh:mm:ss a");
        // const mins = moment.utc(moment(end, "HH:mm:ss").diff(moment(start, "HH:mm:ss"))).format("mm")
        // const totalHours = end.diff(start, 'hours') + " Hrs and " + mins + " Mns";
        const duration = moment.duration(end.diff(start)).asHours();
        const cal = moment.utc(moment(endTime, "HH:mm:ss").diff(moment(startTime, "HH:mm:ss"))).format("HH:mm")

        formRef.current.mutators.setPmolCalculatedTime(cal);
        formRef.current.mutators.setPmolTimeDuration(duration);
    };

    return <div>
        <DefaultCustomModal
            isModalOpen={isOpen}
            onRenderFooter={onRenderFooter}
            title={t('createPmol')}
            handleClose={handleClose}
            showProgressBar={showMyCalenderPbsTaxonomyProgressBar}
        >
            <div style={{height:"80vh",overflowX:'hidden',marginBottom:20}}>
            <Form
                onSubmit={onSubmit}
                initialValues={getInitialFormValues(fCode ?? null)}
                mutators={{
                    setProjectName: (args, state) => {
                        const field = state.fields.projectName;
                        field.change(args[0]);
                    },
                    setPMId: (args, state) => {
                        const field = state.fields.projectManagerId;
                        field.change(args[0]);
                    },
                    setPMName: (args, state) => {
                        const field = state.fields.projectManagerName;
                        field.change(args[0]);
                    },
                    setPMOLName: (args, state) => {
                        const field = state.fields.pmolName;
                        field.change(args[0]);
                    },
                    setPMOLabourResourceItem: (args, state) => {
                        const field = state.fields.pmolLabourResourceItem;
                        field.change(args[0]);
                    },
                    setLabourResourceQuantity: (args, state) => {
                        const field = state.fields.labourResourceQuantity;
                        field.change(args[0]);
                    },
                    setPMOLabourResourceItemLabel: (args, state) => {
                        const field = state.fields.labourResourceItemLabel;
                        field.change(args[0]);
                    },
                    setProjectStatus: (args, state) => {
                        const field = state.fields.projectStatus;
                        field.change(args[0]);
                    },
                    setCustomerId: (args, state) => {
                        const field = state.fields.projectCustomerId;
                        field.change(args[0]);
                    },
                    setCustomerName: (args, state) => {
                        const field = state.fields.projectCustomerName;
                        field.change(args[0]);
                    },
                    setCiaw: (args, state) => {
                        const field = state.fields.ciawSiteCode;
                        field.change(args[0]);
                    },
                    setSiteManagerId: (args, state) => {
                        const field = state.fields.projectSiteManagerId;
                        field.change(args[0]);
                    },
                    setSiteManagerName: (args, state) => {
                        const field = state.fields.projectSiteManagerName;
                        field.change(args[0]);
                    },
                    setPmolCalculatedTime: (args, state) => {
                        const field = state.fields.pmolCalculatedTime;
                        field.change(args[0]);
                    },
                    setMouId: (args, state) => {
                        const field = state.fields.mouId;
                        field.change(args[0]);
                    },
                    setPmolTimeDuration: (args, state) => {
                        const field = state.fields.pmolTimeDuration;
                        field.change(args[0]);
                    },
                    setLabourResourceItemLabel: (args, state) => {
                        const field = state.fields.labourResourceItemLabel;
                        field.change(args[0]);
                    },
                    setForemanName: (args, state) => {
                        const field = state.fields.foremanName;
                        field.change(args[0]);
                    },
                    setSlectedProject: (args, state) => {
                        const field = state.fields.slectedProject;
                        field.change(args[0]);
                    },
                }}
                validate={values => {
                    const errors: any = {};
                    if (!values?.pmolName && !isSubmitting) {
                        errors.pmolName = t('pmolNameRequired');
                    }
                    if (!selectedProjectState?.projectId) {
                        errors.projectName = t('projectNameRequired');
                    }
                    if (!values?.pmolLabourResourceItem) {
                        errors.pmolLabourResourceItem = t('pmolLabourResourceItemRequired');
                    }
                    if (!values?.pbsTaxonomy) {
                        errors.pbsTaxonomy = t('pbsTaxonomyRequired');
                    }
                    // if (!values?.foreman) {
                    //     errors.foreman = t('foremanRequired');
                    // }
                    return errors;
                }}
                render={({
                             handleSubmit,
                             form,
                             // submitting,
                             // pristine,
                             // errors,
                             values,
                         }) => {
                                                formRef.current = form;
                    return <form onSubmit={handleSubmit} id="create-pmol-form" noValidate style={{width: '100%',minWidth:'50vw'}}>

                        {/* Project Definition*/}
                        <Label>{`1. ${t('projectDefinition')}`}</Label>
                        <Stack
                            horizontal
                            wrap
                            styles={stackStyles}
                            tokens={stackTokens}
                        >
                            <Stack.Item grow={6} styles={stackItemStyles}>
                                <div className="full-width">
                                    <Label required={true}>{t('name')}</Label>
                                    <Field name='projectName'>
                                        {({input, meta}) => (
                                            <SearchableDropdown
                                                onChange={(item: any) => {
                                                    if (item) {
                                                        const selectedItem = {
                                                            value: item.value,
                                                            label: item.label,
                                                            sequenceCode: item.sequenceCode,
                                                            contractingUnitId: item.contractingUnitId,
                                                        };
                                                        projectSelector(selectedItem?.sequenceCode);
                                                        input.onChange(selectedItem);
                                                        form.mutators.setSlectedProject(selectedItem)
                                                    } else {
                                                        setSelectedProjectState(null);
                                                        input.onChange(null);
                                                        form.mutators.setSlectedProject( null)
                                                    }
                                                }}
                                                selectedOption={values?.projectName}
                                                promiseOptions={projectOptions}
                                                validationMessage={meta.touched && meta.error && !meta.submitting ? meta.error : ''}
                                                required={false}
                                                disabled={!isMyEnvironment()}
                                            />
                                        )}
                                    </Field>

                                    <Field
                                      name='slectedProject'
                                      component={'input'}
                                      hidden
                                    />
                                </div>
                            </Stack.Item>
                            <Stack.Item grow={6} styles={stackItemStyles}>
                                <div className="full-width">
                                    <Field
                                        name='ciawSiteCode'
                                        component={TextFieldFF}
                                        lable={t('ciaw')}
                                        disabled={true}
                                    />
                                </div>
                            </Stack.Item>
                        </Stack>
                        <Stack
                            horizontal
                            wrap
                            styles={stackStyles}
                            tokens={stackTokens}
                        >
                            <Stack.Item grow={6} styles={stackItemStyles}>
                                <div className="full-width">
                                    <Field
                                        name='projectStatus'
                                        component={DropDownAdaptater}
                                        options={projectState}
                                        lable={t('projectStatus')}
                                        placeholder={t('projectStatus')}
                                        disabled={true}
                                    />
                                </div>
                            </Stack.Item>
                            <Stack.Item grow={6} styles={stackItemStyles}>
                                <div className="full-width">
                                    <Field name='projectManagerId'>
                                        {({input, meta}) => (
                                            <PeoplePicker
                                                label={t('projectManager')}
                                                isDisabled={true}
                                                selectedItem={
                                                    values?.projectManagerId
                                                        ? [
                                                            {
                                                                key: values?.projectManagerId,
                                                                text: values?.projectManagerName,
                                                            },
                                                        ]
                                                        : []
                                                }
                                                // onChange={(items: any) => {
                                                //     if (items && _.isArray(items) && items.length === 0) {
                                                //         input.onChange(null);
                                                //     }
                                                //     if (!items) {
                                                //         input.onChange(null);
                                                //     }
                                                //
                                                //     return items ? items : [];
                                                // }}
                                                // onPersonSelected={(item: any) => {
                                                //     input.onChange(item.cabPersonId);
                                                //     form.mutators.setPMName(item?.text);
                                                //     return item;
                                                // }}
                                            />
                                        )}
                                    </Field>
                                    <Field
                                        name='projectManagerName'
                                        hidden
                                        component={'input'}
                                    />
                                </div>
                            </Stack.Item>
                        </Stack>

                        <Stack
                            horizontal
                            wrap
                            styles={stackStyles}
                            tokens={stackTokens}
                        >
                            <Stack.Item grow={6} styles={stackItemStyles}>
                                <div className="full-width">
                                    <Field name='projectCustomerId'>
                                        {({input, meta}) => (
                                            <PeoplePicker
                                                label={t('customer')}
                                                isDisabled={true}
                                                selectedItem={
                                                    values?.projectCustomerId
                                                        ? [
                                                            {
                                                                key: values?.projectCustomerId,
                                                                text: values?.projectCustomerName,
                                                            },
                                                        ]
                                                        : []
                                                }
                                                // onChange={(items: any) => {
                                                //     if (items && _.isArray(items) && items.length === 0) {
                                                //         input.onChange(null);
                                                //     }
                                                //     if (!items) {
                                                //         input.onChange(null);
                                                //     }
                                                //
                                                //     return items ? items : [];
                                                // }}
                                                // onPersonSelected={(item: any) => {
                                                //     input.onChange(item.cabPersonId);
                                                //     form.mutators.setPMName(item?.text);
                                                //     return item;
                                                // }}
                                            />
                                        )}
                                    </Field>
                                    <Field
                                        name='projectCustomerName'
                                        component={'input'}
                                        hidden
                                    />
                                </div>
                            </Stack.Item>
                            <Stack.Item grow={6} styles={stackItemStyles}>
                                <div className="full-width">
                                    <Field name='projectSiteManagerId'>
                                        {({input, meta}) => (
                                            <PeoplePicker
                                                label={t('siteManager')}
                                                isDisabled={true}
                                                selectedItem={
                                                    values?.projectSiteManagerId
                                                        ? [
                                                            {
                                                                key: values?.projectSiteManagerId,
                                                                text: values?.projectSiteManagerName,
                                                            },
                                                        ]
                                                        : []
                                                }
                                                // onChange={(items: any) => {
                                                //     if (items && _.isArray(items) && items.length === 0) {
                                                //         input.onChange(null);
                                                //     }
                                                //     if (!items) {
                                                //         input.onChange(null);
                                                //     }
                                                //
                                                //     return items ? items : [];
                                                // }}
                                                // onPersonSelected={(item: any) => {
                                                //     input.onChange(item.cabPersonId);
                                                //     form.mutators.setSMName(item?.text);
                                                //     return item;
                                                // }}
                                            />
                                        )}
                                    </Field>
                                    <Field
                                        name='projectSiteManagerName'
                                        component={'input'}
                                        hidden
                                    />
                                </div>
                            </Stack.Item>
                        </Stack>
                                                
                        {/* Product */}
                        <div style={{marginBottom: 4,marginTop:20,padding:5}}>
                            <Label required={true}
                                   style={{paddingTop: 10, marginBottom: -10}}>{`2. ${t('product')}`}</Label>
                            <Field name='pbsTaxonomy'>
                                {({input, meta}) => (
                                    <ProductTaxonomyField
                                        label={''}
                                        treeData={myCalenderPbsTaxonomy ?? []}
                                        selectItemId={values?.pbsTaxonomy ?? ""}
                                        onSelectItem={(id: string) => {
                                            if (id) {
                                                input.onChange(id);
                                                const items = getBreadcrumbItemFromData(myCalenderPbsTaxonomy ,id)
                                                getResponseOfReadLabourByPbsProduct(id);
                                                form.mutators.setPMOLName(items[0].text);
                                            } else if (id && id === values?.pbsTaxonomy) {
                                                input.onChange("");
                                            } else {
                                                input.onChange("");
                                            }
                                        }}
                                        treeLevel={[]}
                                        onFilterTaxonomyNodeChanged={null}
                                        projectBreakdown={undefined}
                                        disabled={!selectedProjectState?.projectId}
                                        showProgressBar={showMyCalenderPbsTaxonomyProgressBar}
                                        isModal={true}
                                        validationMessage={meta.touched && meta.error && !meta.submitting ? meta.error : ''}
                                    />
                                )}
                            </Field>
                        </div>


                        {/*cbc*/}
                        <div style={{marginBottom: 4,marginTop:5}}>
                            <Field name='cbc'>
                                {({input, meta}) => (
                                 <CbcGrid
                                   formData={values}
                                   onChange={(value:any)=>{
                                       input.onChange(value)
                                   }}
                                 />
                                )}
                            </Field>
                        </div>

                        {/* PMOL */}
                        <div style={{marginBottom: 4,marginTop:20}}>
                        <Label >{`4. ${t('pmol')}`}</Label>
                        <Stack
                            horizontal
                            wrap
                            styles={stackStyles}
                            tokens={stackTokens}
                        >
                            <Stack.Item grow={6} styles={stackItemStyles}>
                                <div className="full-width">
                                    <Field
                                        name='pmolName'
                                        component={TextFieldFF}
                                        lable={t('pmolName')}
                                        required={true}
                                        defaultValue={values?.name}
                                    />
                                </div>
                            </Stack.Item>
                            <Stack.Item grow={6} styles={stackItemStyles}>
                                <div className="full-width">
                                    <Field
                                        name='pmolType'
                                        component={DropDownAdaptater}
                                        options={pmolType}
                                        lable={t('pmolType')}
                                        required={false}
                                    />
                                </div>
                            </Stack.Item>
                        </Stack>

                        <Stack
                            horizontal
                            wrap
                            styles={stackStyles}
                            tokens={stackTokens}
                        >
                            <Stack.Item grow={6} styles={stackItemStyles}>
                                <div className="full-width" style={{zIndex: 10}}>
                                    <Label required={false}>{t('labourResourceItem')}</Label>
                                    <Field name='pmolLabourResourceItem'>
                                        {({input, meta}) => (

                                            <CustomDropdown
                                                id={values?.pmolLabourResourceItem + 'pmolLabourResourceItem'}
                                                onChange={(
                                                    key: string,
                                                    text: string,
                                                    values: any,
                                                ) => {
                                                    if (key) {
                                                        input.onChange(key);
                                                        form.mutators.setLabourResourceItemLabel(text);
                                                        if(values.quantity && values.quantity!=null){
                                                            formRef.current.mutators.setLabourResourceQuantity(values.quantity);
                                                        }
                                                        form.mutators.setMouId(values?.mouId);
                                                    } else {
                                                        input.onChange(null);
                                                        form.mutators.setLabourResourceItemLabel(null);
                                                        form.mutators.setMouId(null);
                                                        form.mutators.setLabourResourceQuantity(0);
                                                    }
                                                }}
                                                selectedOption={
                                                    values && values?.pmolLabourResourceItem
                                                        ? {
                                                            value: values?.pmolLabourResourceItem,
                                                            label: values?.labourResourceItemLabel
                                                        } : null}
                                                promiseOptions={labourResourceOptions}
                                                // options = {labourOptions}
                                                validationMessage={meta.touched && meta.error && !meta.submitting ? meta.error : ''}
                                                required={false}
                                                reference={''}
                                                noHeight={true}
                                                // disabled={values?.contractingUnit && values?.contractingUnit.length <= 0 || !!getProject()}
                                            />
                                        )}
                                    </Field>
                                    <Field
                                        name='mouId'
                                        component={'input'}
                                        hidden
                                    />
                                    <Field
                                        name='labourResourceItemLabel'
                                        component={'input'}
                                        hidden
                                    />
                                </div>
                            </Stack.Item>
                            <Stack.Item grow={6} styles={stackItemStyles}>
                                <div className="full-width">
                                    <Field
                                        name='labourResourceQuantity'
                                        component={TextFieldFF}
                                        lable={t('labourResourceQuantity')}
                                        type={'number'}
                                        step={1}
                                        min={0}
                                    />
                                </div>
                            </Stack.Item>
                        </Stack>

                        <Stack
                            horizontal
                            wrap
                            styles={stackStyles}
                            tokens={stackTokens}
                        >
                            <Stack.Item grow={6} styles={stackItemStyles}>
                                <div style={{marginTop:10}} className="half-width">
                                    <Field name='foreman'>
                                        {({input, meta}) => (
                                            <PeoplePicker
                                                label={t('foreman')}
                                                isDisabled={false}
                                                selectedItem={
                                                    values?.foreman
                                                        ? [
                                                            {
                                                                key: values?.foreman,
                                                                text: values?.foremanName,
                                                            },
                                                        ]
                                                        : []
                                                }
                                                onChange={(items: any) => {
                                                    if (items && _.isArray(items) && items.length === 0) {
                                                        input.onChange(null);
                                                    }
                                                    if (!items) {
                                                        input.onChange(null);
                                                    }

                                                    return items ? items : [];
                                                }}
                                                onPersonSelected={(item: any) => {
                                                    input.onChange(item.cabPersonId);
                                                    form.mutators.setForemanName(item?.text);
                                                    return item;
                                                }}
                                                // required={false}
                                                validationMessage={meta.touched && meta.error && !meta.submitting ? meta.error : ''}
                                            />
                                        )}
                                    </Field>
                                    <Field
                                        name='foremanName'
                                        hidden
                                        component={'input'}
                                    />
                                </div>
                            </Stack.Item>
                        </Stack>

                        <Stack
                            horizontal
                            wrap
                            styles={stackStyles}
                            tokens={stackTokens}
                        >
                            <Stack.Item grow={6} styles={stackItemStyles}>
                                <div className={"time-field"}>
                                    <div style={{width:300}}>
                                        <Field
                                            name="pmolDate"
                                            component={DatePickerAdapter}
                                            lable={t('date')}
                                            parse={value => value && formatDateTimeToISOString(value)}
                                            format={value => (value ? new Date(value) : null)}
                                            disabled={false}
                                        />
                                    </div>
                                    <Field name='pmolStartTime'>
                                        {({input, meta}) => (
                                            <MaskedTextField
                                                label={t('start')}
                                                value={values?.pmolStartTime ?? ''}
                                                onChange={(e: any, newValue?: string) => {
                                                    // const timeValidation: boolean = moment(newValue, 'HH:mm', true).isValid();
                                                    if (newValue) {
                                                        input.onChange(newValue);
                                                    } else {
                                                        input.onChange(null);
                                                    }
                                                    setCalcTime(newValue, values?.pmolEndTime);
                                                }}
                                                mask="HH:mM"
                                                maskFormat={maskFormat}
                                                maskChar="_"
                                                disabled={false}
                                            />
                                        )}
                                    </Field>
                                    <Field name='pmolEndTime'>
                                        {({input, meta}) => (
                                            <MaskedTextField
                                                label={t('end')}
                                                value={values?.pmolEndTime ?? ''}
                                                onChange={(e: any, newValue?: string) => {
                                                    // const timeValidation: boolean = moment(newValue, 'HH:mm', true).isValid();
                                                    if (newValue) {
                                                        input.onChange(newValue);
                                                        setCalcTime(values?.pmolStartTime, newValue);
                                                    } else {
                                                        input.onChange(null);
                                                    }
                                                }}
                                                mask="HH:mM"
                                                maskFormat={maskFormat}
                                                maskChar="_"
                                                disabled={false}
                                            />
                                        )}
                                    </Field>
                                </div>
                            </Stack.Item>
                            <Stack.Item grow={6} align="end" styles={stackItemStyles}>
                                <div className={"time-field"}>
                                    <Field
                                        lable={t('duration')}
                                        name='pmolCalculatedTime'
                                        component={TextFieldFF}
                                        value={values?.pmolCalculatedTime ?? ''}
                                        disabled={true}
                                    />
                                    <Field
                                        name='pmolTimeDuration'
                                        component={'input'}
                                        hidden
                                    />
                                    <div className={"finish-check-box"}>
                                        <Field name='pmolFinish'>
                                            {({input}) => (
                                                <Checkbox
                                                    checked={values?.pmolFinish}
                                                    label={t('finish')}
                                                    className='btn btn-outline-success'
                                                    styles={{
                                                        root: {
                                                            verticalAlign: 'middle',
                                                            marginBottom: 9,
                                                        },
                                                    }}
                                                    onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
                                                        input.onChange(checked);
                                                    }}
                                                    boxSide={'start'}
                                                />
                                            )}
                                        </Field>
                                    </div>
                                </div>
                            </Stack.Item>
                        </Stack>
                        </div>


                         <div style={{marginTop:20}} className="pmol-comments-my-calendar">
                            <Field
                                name="pmolComments"
                                component={MultiLineTextFieldWrapper}
                                label={t('comments')}
                                disabled={false}
                                rows={5}
                            />
                        </div>

                        {/*<div style={{padding: 10}}>{JSON.stringify(values, null, 2)}</div>*/}
                    </form>
                }}
            />
            </div>
        </DefaultCustomModal>
    </div>
}
