import {
  ActionButton,
  Dropdown,
  IDropdownOption,
  IIconProps,
  Label,
  TextField,
  Toggle,
} from '@fluentui/react';
import React, { useEffect } from 'react';
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  readProjectVPData,
  readVPPBSLocationTaxonomyData,
  readVPPBSUtilityTaxonomyData,
  saveVPStateAttr,
} from '../../../reducers/visualPlanReducer';
import CustomDatePicker from '../../../shared/customDatePicker/customDatePicker';
// import 'style.css';
import { ViewMode } from '../../../shared/ganttv2';
import {
  formatDateTimeToISOString,
  getSelectedFilterDates,
  isPPOpenFromPlanboard,
  updateVPProductStatusFilter,
  updateVPStandardFilter,
} from '../../../shared/util';
import ModeSwitcher from '../../../shared/ganttViewSwitcher/viewSwitcher';
import { useDebouncedCallback } from 'use-debounce/lib';
import ModeSwitcherWithRadioButtons from '../../../shared/ganttViewSwitcher/viewSwitcherWithRadioButtons';

type ViewSwitcherProps = {
  isChecked: boolean;
  onViewListChange: (isChecked: boolean) => void;
  onViewModeChange: (viewMode: ViewMode) => void;
  renderFilterComponent: any;
  msDropDowns: any;
};
export const ViewSwitcher: React.FC<ViewSwitcherProps> = ({
  isChecked,
  onViewListChange,
  onViewModeChange,
  renderFilterComponent,
  msDropDowns,
}) => {
  const [selectedKeys, setSelectedKeys] = React.useState<string[]>([]);
  const [selectedResourceKeys, setSelectedResourceTypeKeys] = React.useState<
    string[]
  >([]);
  const { t } = useTranslation();
  const [startDate, setStartDate] = React.useState<Date | null>(null);
  const [endDate, setEndDate] = React.useState<Date | null>(null);
  const [withParent, setWithParent] = React.useState<boolean>(false);
  const [itemType, setItemType] = React.useState<string | number>('0');
  const [selectedMode, setSelectedViewMode] = React.useState<ViewMode>(
    ViewMode.Week
  );
  const [standeredFilter, setStanderedFilter] = React.useState<any>('5');
  const [searchTriggered, setSearchTriggered] = React.useState<boolean>(false);
  const [prodStatusFilterKey, setProdStatusFilterKey] =
    React.useState<any>('1');
  const [search, setSearch] = React.useState<string>('');

  const poDropDowns: any = useSelector(
    (state: any) => state.po.purchaseOrderDropDowns
  );
  const cpcDropDowns: any = useSelector(
    (state: any) => state.productCatalogue.resourceTypes
  );
  const {
    selectedProjectItemType,
    selectedStartDateProjectItem,
    selectedEndDateProjectItem,
  }: any = useSelector((state: any) => state.vp);

  const standerdFilterOptions = useSelector((state: any) =>
    selectedProjectItemType == 6
      ? updateVPStandardFilter(state.vp.vpFilterDropdown)
      : state.vp.vpFilterDropdown
  );

  const productStatusFilterOptions = useSelector((state: any) =>
    selectedProjectItemType == 6
      ? updateVPProductStatusFilter(state.projectBreakdown?.productStates)
      : state.projectBreakdown?.productStates
  );

  const addFriendIcon: IIconProps = { iconName: 'Refresh' };

  const dispatch = useDispatch();
  const selectedVPRequestType: any = useSelector(
    (state: any) => state.vp.vpProjectSelectedShortcutpaneItems
  );
  const onSubmit = async (values: any) => {};

  const taxonomyTypeDropDowns: IDropdownOption[] = [
    { key: '0', text: 'All' },
    { key: '1', text: 'PBS' },
    { key: '2', text: 'BOR' },
    { key: '3', text: 'PMOL' },
  ];

  // If isPPOpenFromPlanBoard the default is week plan
  useEffect(() => {
    if (isPPOpenFromPlanboard()) {
      dispatch(saveVPStateAttr('selectedProjectItemType', 6));
    }
  }, []);

  useEffect(() => {
    if (selectedProjectItemType) {
      setItemType(selectedProjectItemType.toString());
      if (selectedProjectItemType === 6) {
        // setStartDate(new Date(selectedStartDateProjectItem));
        // setEndDate(new Date(selectedEndDateProjectItem));
        // setStanderedFilter('2')
        // setStartDate(getSelectedFilterDates(['2'])[0])
        // setEndDate(getSelectedFilterDates(['2'])[1])
      } else {
        setStanderedFilter('5');
        setStartDate(getSelectedFilterDates(['5'])[0]);
        setEndDate(getSelectedFilterDates(['5'])[1]);
      }
    }
  }, [
    selectedProjectItemType,
    selectedStartDateProjectItem,
    selectedEndDateProjectItem,
  ]);

  useEffect(() => {
    if (selectedProjectItemType === 6) {
      setCalenderVariables(
        getSelectedFilterDates(['2'])[0],
        getSelectedFilterDates(['2'])[1]
      );
      // setStartDate(getSelectedFilterDates(['2'])[0]);
      // setEndDate(getSelectedFilterDates(['2'])[1]);
      setStanderedFilter('2');
    }
  }, []);

  useEffect(() => {
    setSelectedViewMode(ViewMode.Week);
    if (selectedProjectItemType === 6) {
      setStanderedFilter('2');
      setProdStatusFilterKey('1');
    }
  }, [selectedProjectItemType]);

  const debounced = useDebouncedCallback(
    (value: any) => {
      setSearch(value);
      if (
        selectedProjectItemType !== 7 &&
        selectedProjectItemType !== 8 &&
        selectedProjectItemType !== 6
      ) {
        dispatch(
          readProjectVPData({
            type: [itemType],
            startDate: formatDateTimeToISOString(startDate!!),
            endDate: formatDateTimeToISOString(endDate!!),
            title: value,
            withParent: withParent,
          })
        );
      } else if (selectedProjectItemType === 7) {
        dispatch(
          readVPPBSUtilityTaxonomyData({
            type: ['7'],
            startDate: formatDateTimeToISOString(startDate!!),
            endDate: formatDateTimeToISOString(endDate!!),
            title: value,
            withParent: withParent,
          })
        );
      } else if (selectedProjectItemType === 8) {
        dispatch(
          readVPPBSUtilityTaxonomyData({
            type: ['8'],
            startDate: formatDateTimeToISOString(startDate!!),
            endDate: formatDateTimeToISOString(endDate!!),
            title: value,
            withParent: withParent,
          })
        );
      } else if (selectedProjectItemType === 6) {
        dispatch(
          readProjectVPData({
            type: ['4'],
            startDate: formatDateTimeToISOString(startDate!!),
            endDate: formatDateTimeToISOString(endDate!!),
            title: value,
            withParent: withParent,
          })
        );
      }
    },
    // delay in ms
    1000
  );
  const setCalenderVariables = (startDate: Date, endDate: Date) => {
    setStartDate(startDate);
    setEndDate(endDate);

    dispatch(saveVPStateAttr('selectedStartDateProjectItem', startDate));
    dispatch(saveVPStateAttr('selectedEndDateProjectItem', endDate));
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
        height: 70,
        width: '100%',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: 'white',
          flexGrow: 0.2,
        }}
      >
        <Form
          onSubmit={onSubmit}
          initialValues={{
            poType: itemType,
            startDate: startDate ? startDate : null,
            endDate: endDate ? endDate : null,
            date: '5',
            searchString: null,
          }}
          mutators={{
            setHeaderDocs: (args, state, utils) => {
              const field = state.fields['header.files'];
              field.change(args[0]);
            },
            setDateRange: (args, state, utils) => {
              const from = state.fields['startDate'];
              const to = state.fields['endDate'];
              from.change(getSelectedFilterDates(args)[0]);
              to.change(getSelectedFilterDates(args)[1]);
              setStartDate(getSelectedFilterDates(args)[0]);
              setEndDate(getSelectedFilterDates(args)[1]);
              dispatch(
                saveVPStateAttr(
                  'scrollPosDate',
                  getSelectedFilterDates(args)[0]
                )
              );
              if (
                selectedProjectItemType !== 7 &&
                selectedProjectItemType !== 8 &&
                selectedProjectItemType !== 6
              ) {
                dispatch(
                  readProjectVPData({
                    type: [itemType],
                    startDate: formatDateTimeToISOString(
                      getSelectedFilterDates(args)[0]
                    ),
                    endDate: formatDateTimeToISOString(
                      getSelectedFilterDates(args)[1]
                    ),
                    withParent: withParent,
                  })
                );
              } else if (selectedProjectItemType === 6) {
                dispatch(
                  readProjectVPData({
                    type: [4],
                    startDate: formatDateTimeToISOString(
                      getSelectedFilterDates(args)[0]
                    ),
                    endDate: formatDateTimeToISOString(
                      getSelectedFilterDates(args)[1]
                    ),
                    productStatus:
                      prodStatusFilterKey == 1 ? null : prodStatusFilterKey,
                    withParent: withParent,
                  })
                );
              } else if (selectedProjectItemType === 7) {
                dispatch(
                  readVPPBSUtilityTaxonomyData({
                    type: [itemType],
                    startDate: formatDateTimeToISOString(startDate!!),
                    endDate: formatDateTimeToISOString(endDate!!),
                    withParent: withParent,
                  })
                );
              } else if (selectedProjectItemType === 8) {
                dispatch(
                  readVPPBSLocationTaxonomyData({
                    type: [itemType],
                    startDate: formatDateTimeToISOString(startDate!!),
                    endDate: formatDateTimeToISOString(endDate!!),
                    withParent: withParent,
                  })
                );
              }
            },
          }}
          // validate={ validate }
          render={({ handleSubmit, form, submitting, pristine, values }) => {
            // props.submit(handleSubmit);

            return (
              <form onSubmit={handleSubmit} noValidate>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flex: 1,
                    height: 70,
                    width: '100%',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      flex: 1,
                      height: 70,
                      width: '100%',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        flex: 1,
                        height: 70,
                        width: '100%',
                      }}
                    >
                      {
                        <>
                          <div
                            style={{
                              width: selectedProjectItemType == 6 ? 320 : 200,
                              marginLeft: 15,
                              marginRight: 15,
                            }}
                          >
                            <div
                              style={{
                                width: 320,
                                marginLeft: 15,
                                display: 'flex',
                                flexDirection: 'row',
                              }}
                            >
                              <Field name="searchString">
                                {({ input, meta }) => (
                                  <div style={{ marginTop: 30 }}>
                                    <TextField
                                      disabled={
                                        selectedProjectItemType == 8 ||
                                        selectedProjectItemType == 7
                                      }
                                      value={values.searchString}
                                      placeholder={t('search')}
                                      onChange={(event, value) => {
                                        if (value?.length!! >= 1) {
                                          input.onChange(value);
                                        } else {
                                          input.onChange(null);
                                        }
                                        if (value?.length!! >= 3) {
                                          setSearchTriggered(true);
                                          debounced(value);
                                        } else if (
                                          value?.length!! === 0 &&
                                          searchTriggered
                                        ) {
                                          setSearchTriggered(false);
                                          debounced(null);
                                        }
                                      }}
                                    />
                                  </div>
                                )}
                              </Field>
                              {selectedProjectItemType == 6 && (
                                <div style={{ marginLeft: 30 }}>
                                  <Toggle
                                    label="Search Options"
                                    checked={withParent}
                                    onText={t('includeParent')}
                                    offText={t('excludeParent')}
                                    onChange={(
                                      ev: React.MouseEvent<HTMLElement>,
                                      checked?: boolean
                                    ) => {
                                      console.log(
                                        'toggle is ' +
                                          (checked ? 'checked' : 'not checked')
                                      );
                                      setWithParent(checked!!);
                                      let type = ['0'];
                                      if (values.poType === '6') {
                                        type = ['4'];
                                      } else {
                                        type = values.poType;
                                      }
                                      dispatch(
                                        readProjectVPData({
                                          type: type,
                                          title: search,
                                          startDate: formatDateTimeToISOString(
                                            startDate!!
                                          ),
                                          endDate: formatDateTimeToISOString(
                                            endDate!!
                                          ),
                                          withParent: checked,
                                        })
                                      );
                                    }}
                                    role="checkbox"
                                  />
                                </div>
                              )}
                            </div>
                          </div>

                          {selectedProjectItemType !== 6 &&
                            selectedProjectItemType !== 7 &&
                            selectedProjectItemType !== 8 && (
                              <div style={{ width: 180, marginLeft: 20 }}>
                                <Field name="poType">
                                  {({ input, meta }) => (
                                    <Dropdown
                                      placeholder={t('type')}
                                      label={t('type')}
                                      selectedKey={values.poType}
                                      // eslint-disable-next-line react/jsx-no-bind
                                      onChange={(
                                        event: React.FormEvent<HTMLDivElement>,
                                        item: IDropdownOption | undefined
                                      ) => {
                                        if (item) {
                                          setSelectedKeys(
                                            item.selected
                                              ? [
                                                  ...selectedKeys,
                                                  item.key as string,
                                                ]
                                              : selectedKeys.filter(
                                                  (key) => key !== item.key
                                                )
                                          );
                                          setItemType(item.key);
                                          dispatch(
                                            saveVPStateAttr(
                                              'selectedProjectItemType',
                                              item.key
                                            )
                                          );
                                          dispatch(
                                            readProjectVPData({
                                              type: [item.key],
                                              startDate:
                                                formatDateTimeToISOString(
                                                  startDate!!
                                                ),
                                              endDate:
                                                formatDateTimeToISOString(
                                                  endDate!!
                                                ),
                                              withParent: withParent,
                                            })
                                          );
                                          input.onChange(item.key);
                                        }
                                      }}
                                      options={taxonomyTypeDropDowns}
                                    />
                                  )}
                                </Field>
                              </div>
                            )}

                          {selectedProjectItemType == 6 && (
                            <div style={{ width: 180, marginLeft: 20 }}>
                              <Field name="productStatus">
                                {({ input, meta }) => (
                                  <Dropdown
                                    placeholder={t('productStatus')}
                                    label={t('productStatus')}
                                    selectedKey={prodStatusFilterKey}
                                    // eslint-disable-next-line react/jsx-no-bind
                                    onChange={(
                                      event: React.FormEvent<HTMLDivElement>,
                                      item: IDropdownOption | undefined
                                    ) => {
                                      if (item) {
                                        setProdStatusFilterKey(item.key);
                                        input.onChange(
                                          dispatch(
                                            readProjectVPData({
                                              type: ['4'],
                                              startDate:
                                                formatDateTimeToISOString(
                                                  startDate!!
                                                ),
                                              endDate:
                                                formatDateTimeToISOString(
                                                  endDate!!
                                                ),
                                              productStatus:
                                                item.key == 1 ? null : item.key,
                                              withParent: withParent,
                                            })
                                          )
                                        );
                                      }
                                    }}
                                    options={productStatusFilterOptions}
                                  />
                                )}
                              </Field>
                            </div>
                          )}

                          <div style={{ width: 180, marginLeft: 15 }}>
                            <Field name="date">
                              {({ input, meta }) => (
                                <Dropdown
                                  placeholder={t('standardFilter')}
                                  label={t('standardFilter')}
                                  selectedKey={standeredFilter}
                                  // disabled={ selectedProjectItemType === 6 }
                                  // eslint-disable-next-line react/jsx-no-bind
                                  onChange={(event, value) => {
                                    let date = null;
                                    if (value) {
                                      date = value.key;
                                      input.onChange(value.key);
                                      setStanderedFilter(value.key);
                                    } else {
                                      date = null;
                                      input.onChange(null);
                                    }
                                    form.mutators.setDateRange(value?.key);
                                    if (value?.key === '5') {
                                      onViewModeChange(ViewMode.QuaterYear);
                                      // setIsProjectSeleted(true)
                                      setSelectedViewMode(ViewMode.QuaterYear);
                                    } else {
                                      onViewModeChange(ViewMode.Week);
                                      // setIsProjectSeleted(false)
                                      setSelectedViewMode(ViewMode.Week);
                                    }
                                  }}
                                  options={standerdFilterOptions}
                                />
                              )}
                            </Field>
                          </div>

                          <div
                            className={'vpDatePicker180'}
                            style={{ width: 180, marginLeft: 15 }}
                          >
                            <Label style={{ width: 180 }}>
                              {t('fromDate')}:
                            </Label>
                            <Field name="startDate">
                              {({ input, meta }) => (
                                <CustomDatePicker
                                  setValue={values.startDate}
                                  // disabled={ selectedProjectItemType === 6 }
                                  getValue={(date: Date) => {
                                    const clDate = new Date(date);
                                    const newDate = new Date(
                                      clDate.setDate(clDate.getDate() + 30)
                                    );

                                    const week = new Date(date);
                                    const weekDate = new Date(
                                      week.setDate(date.getDate() + 7)
                                    );

                                    input.onChange(date);
                                    setStanderedFilter('8');

                                    if (
                                      selectedProjectItemType !== 7 &&
                                      selectedProjectItemType !== 8 &&
                                      selectedProjectItemType !== 6
                                    ) {
                                      setCalenderVariables(date, newDate);
                                      dispatch(
                                        readProjectVPData({
                                          type: values.poType
                                            ? [values.poType]
                                            : ['0'],
                                          startDate:
                                            formatDateTimeToISOString(date),
                                          endDate:
                                            formatDateTimeToISOString(newDate),
                                          withParent: withParent,
                                        })
                                      );
                                    } else if (selectedProjectItemType === 7) {
                                      setCalenderVariables(date, newDate);
                                      dispatch(
                                        readVPPBSUtilityTaxonomyData({
                                          type: [itemType],
                                          startDate: formatDateTimeToISOString(
                                            startDate!!
                                          ),
                                          endDate: formatDateTimeToISOString(
                                            endDate!!
                                          ),
                                          withParent: withParent,
                                        })
                                      );
                                    } else if (selectedProjectItemType === 8) {
                                      setCalenderVariables(date, newDate);
                                      dispatch(
                                        readVPPBSLocationTaxonomyData({
                                          type: [itemType],
                                          startDate: formatDateTimeToISOString(
                                            startDate!!
                                          ),
                                          endDate: formatDateTimeToISOString(
                                            endDate!!
                                          ),
                                          withParent: withParent,
                                        })
                                      );
                                    } else if (selectedProjectItemType === 6) {
                                      setCalenderVariables(date, weekDate); //Week Plan
                                      dispatch(
                                        readProjectVPData({
                                          type: ['4'],
                                          startDate:
                                            formatDateTimeToISOString(date),
                                          endDate:
                                            formatDateTimeToISOString(weekDate),
                                          productStatus:
                                            prodStatusFilterKey == 1
                                              ? null
                                              : prodStatusFilterKey,
                                          withParent: withParent,
                                        })
                                      );
                                    }
                                  }}
                                />
                              )}
                            </Field>
                          </div>

                          <div
                            className={'vpDatePicker180'}
                            style={{ width: 180, marginLeft: 15 }}
                          >
                            <Label style={{ width: 180 }}>{t('toDate')}:</Label>
                            <Field name="endDate">
                              {({ input, meta }) => (
                                <CustomDatePicker
                                  setMinDate={
                                    startDate
                                      ? startDate
                                      : new Date(
                                          form.getFieldState('startDate')?.value
                                        )
                                  }
                                  setValue={values.endDate}
                                  //disabled={ selectedProjectItemType === 6 }
                                  getValue={(date: Date) => {
                                    input.onChange(date);
                                    setStanderedFilter('8');
                                    setEndDate(date);
                                    dispatch(
                                      saveVPStateAttr(
                                        'selectedEndDateProjectItem',
                                        date
                                      )
                                    );

                                    if (
                                      selectedProjectItemType !== 7 &&
                                      selectedProjectItemType !== 8 &&
                                      selectedProjectItemType !== 6
                                    ) {
                                      dispatch(
                                        readProjectVPData({
                                          type: values.poType
                                            ? [values.poType]
                                            : ['0'],
                                          startDate: formatDateTimeToISOString(
                                            startDate!!
                                          ),
                                          endDate:
                                            formatDateTimeToISOString(date),
                                        })
                                      );
                                    } else if (selectedProjectItemType === 7) {
                                      dispatch(
                                        readVPPBSUtilityTaxonomyData({
                                          type: [itemType],
                                          startDate: formatDateTimeToISOString(
                                            startDate!!
                                          ),
                                          endDate: formatDateTimeToISOString(
                                            endDate!!
                                          ),
                                        })
                                      );
                                    } else if (selectedProjectItemType === 8) {
                                      dispatch(
                                        readVPPBSLocationTaxonomyData({
                                          type: [itemType],
                                          startDate: formatDateTimeToISOString(
                                            startDate!!
                                          ),
                                          endDate: formatDateTimeToISOString(
                                            endDate!!
                                          ),
                                        })
                                      );
                                    } else if (selectedProjectItemType === 6) {
                                      dispatch(
                                        readProjectVPData({
                                          type: ['4'],
                                          startDate: formatDateTimeToISOString(
                                            startDate!!
                                          ),
                                          endDate:
                                            formatDateTimeToISOString(date),
                                          productStatus:
                                            prodStatusFilterKey == 1
                                              ? null
                                              : prodStatusFilterKey,
                                        })
                                      );
                                    }
                                  }}
                                />
                              )}
                            </Field>
                          </div>
                        </>
                      }

                      {selectedProjectItemType === 6 ? (
                        <ModeSwitcherWithRadioButtons
                          onViewModeChange={(t: any) => {
                            onViewModeChange(t);
                            setSelectedViewMode(t);
                          }}
                          onViewListChange={onViewListChange}
                          isCheck={isChecked}
                          selectedViewMode={selectedMode}
                        />
                      ) : (
                        <ModeSwitcher
                          onViewModeChange={(t: any) => {
                            onViewModeChange(t);
                            setSelectedViewMode(t);
                          }}
                          onViewListChange={onViewListChange}
                          isCheck={isChecked}
                          selectedViewMode={selectedMode}
                        />
                      )}

                      <ActionButton
                        onClick={() => {
                          if (
                            selectedProjectItemType !== 7 &&
                            selectedProjectItemType !== 8 &&
                            selectedProjectItemType !== 6
                          ) {
                            dispatch(
                              readProjectVPData({
                                type: values.poType ? [values.poType] : ['0'],
                                startDate: formatDateTimeToISOString(
                                  startDate!!
                                ),
                                endDate: formatDateTimeToISOString(endDate!!),
                              })
                            );
                          } else if (selectedProjectItemType === 7) {
                            dispatch(
                              readVPPBSUtilityTaxonomyData({
                                type: [itemType],
                                startDate: formatDateTimeToISOString(
                                  startDate!!
                                ),
                                endDate: formatDateTimeToISOString(endDate!!),
                              })
                            );
                          } else if (selectedProjectItemType === 8) {
                            dispatch(
                              readVPPBSLocationTaxonomyData({
                                type: [itemType],
                                startDate: formatDateTimeToISOString(
                                  startDate!!
                                ),
                                endDate: formatDateTimeToISOString(endDate!!),
                              })
                            );
                          } else if (selectedProjectItemType === 6) {
                            dispatch(
                              readProjectVPData({
                                type: ['4'],
                                startDate: formatDateTimeToISOString(
                                  startDate!!
                                ),
                                endDate: formatDateTimeToISOString(endDate!!),
                              })
                            );
                          }
                        }}
                        iconProps={addFriendIcon}
                        allowDisabledFocus
                        disabled={false}
                        checked={false}
                        style={{ marginTop: 15 }}
                      >
                        {t('refresh')}
                      </ActionButton>
                    </div>
                  </div>
                </div>
                {/* <pre>{ JSON.stringify(values, 0, 2) }</pre> */}
              </form>
            );
          }}
        />
      </div>
    </div>
  );
};
