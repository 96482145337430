import React, { useEffect, useRef } from 'react';
import { SlideImage } from 'yet-another-react-lightbox/*';
import { BarTask } from '../../types/bar-task';
import { ChartType, Task, VisibilityProps } from '../../types/public-types';

export type TaskListProps = {
  headerHeight: number;
  rowWidth: string;
  fontFamily: string;
  fontSize: string;
  rowHeight: number;
  ganttHeight: number;
  scrollY: number;
  locale: string;
  tasks: Task[];
  taskListRef: React.RefObject<HTMLDivElement>;
  horizontalContainerClass?: string;
  selectedTask: BarTask | undefined;
  setSelectedTask: (task: string) => void;
  onExpanderClick: (task: Task) => void;
  onVisibilityChanged: (data: any) => void;
  onVisibilityToggle: (data: VisibilityProps) => void;
  setCarousalImages: (images: SlideImage[]) => void;
  setIsImageCarousalOpen: (isOpen: boolean) => void;
  TaskListHeader: React.FC<{
    headerHeight: number;
    rowWidth: string;
    fontFamily: string;
    fontSize: string;
    chartType: string;
  }>;
  TaskListTable: React.FC<{
    rowHeight: number;
    rowWidth: string;
    fontFamily: string;
    fontSize: string;
    locale: string;
    tasks: Task[];
    listTasks: Task[];
    isInitialCollaps: boolean;
    selectedTaskId: string;
    setSelectedTask: (taskId: string) => void;
    onExpanderClick: (task: Task) => void;
    onVisibilityChanged: (data: any) => void;
    canDrag: (props: any) => boolean;
    canDrop: (props: any) => boolean;
    onMoveNode: (
      treeData: any,
      node: any,
      nextParentNode: any,
      prevPath: any,
      prevTreeIndex: any,
      nextPath: any,
      nextTreeIndex: any,
      nodeTwo: any
    ) => void;
    chartType: ChartType;
    searchQuery: string;
    searchMethod: ({ node, searchQuery }: any) => void;
    searchFocusOffset: number;
    searchFinishCallback: (matches: any) => void;
    onlyExpandSearchedNodes: boolean;
    onTreeDataChange: (treeData: any[]) => void;
    onVisibilityToggle: (data: any) => void;
    setCarousalImages: (images: SlideImage[]) => void;
    setIsImageCarousalOpen: (isOpen: boolean) => void;
  }>;
  listTasks: Task[];
  isInitialCollaps: boolean;
  canDrag: (dropProps: {
    node: any;
    path: number[] | string[];
    treeIndex: number;
    lowerSiblingCounts: number[];
    isSearchMatch: boolean;
    isSearchFocus: boolean;
  }) => boolean;
  canDrop: (dropProp: {
    node: any;
    prevPath: number[] | string[];
    prevParent: any;
    prevTreeIndex: number;
    nextPath: number[] | string[];
    nextParent: any;
    nextTreeIndex: number;
  }) => boolean;
  onMoveNode: (
    treeData: any,
    node: any,
    nextParentNode: any,
    prevPath: any,
    prevTreeIndex: any,
    nextPath: any,
    nextTreeIndex: any,
    nodeTwo: any
  ) => void;
  chartType: ChartType;
  searchQuery: string;
  searchMethod: ({ node, searchQuery }: any) => void;
  searchFocusOffset: number;
  searchFinishCallback: (matches: any) => void;
  onlyExpandSearchedNodes: boolean;
  onTreeDataChange: (treeData: any[]) => void;
};

export const TaskList: React.FC<TaskListProps> = ({
  headerHeight,
  fontFamily,
  fontSize,
  rowWidth,
  rowHeight,
  scrollY,
  tasks,
  listTasks,
  isInitialCollaps,
  canDrag,
  canDrop,
  onMoveNode,
  selectedTask,
  setSelectedTask,
  onExpanderClick,
  locale,
  ganttHeight,
  taskListRef,
  horizontalContainerClass,
  TaskListHeader,
  TaskListTable,
  onVisibilityChanged,
  chartType,
  searchQuery,
  searchMethod,
  searchFocusOffset,
  searchFinishCallback,
  onlyExpandSearchedNodes,
  onTreeDataChange,
  onVisibilityToggle,
  setCarousalImages,
  setIsImageCarousalOpen,
}) => {
  const horizontalContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (horizontalContainerRef.current) {
      horizontalContainerRef.current.scrollTop = scrollY;
    }
  }, [scrollY]);

  const headerProps = {
    headerHeight,
    fontFamily,
    fontSize,
    rowWidth,
    chartType,
  };
  const selectedTaskId = selectedTask ? selectedTask.id : '';
  const tableProps = {
    rowHeight,
    rowWidth,
    fontFamily,
    fontSize,
    tasks,
    listTasks,
    isInitialCollaps,
    canDrag,
    canDrop,
    onMoveNode,
    locale,
    selectedTaskId: selectedTaskId,
    setSelectedTask,
    onExpanderClick,
    onVisibilityChanged,
    chartType,
    onTreeDataChange,
    searchQuery,
    searchMethod,
    searchFocusOffset,
    searchFinishCallback,
    onlyExpandSearchedNodes,
    onVisibilityToggle,
    setCarousalImages,
    setIsImageCarousalOpen,
  };

  return (
    <div className="aaaaaaaa" ref={taskListRef}>
      <TaskListHeader {...headerProps} />
      <div
        ref={horizontalContainerRef}
        className={'gantt4k'}
        //className={ "gantt4k " +horizontalContainerClass }
        style={ganttHeight ? { height: ganttHeight } : {}}
      >
        <TaskListTable {...tableProps} />
      </div>
    </div>
  );
};
