import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import {
  backOfficersOnlyRoutes,
  getAccessingLevel,
  getContractingUnit,
  getProject,
  getUserProjectscodes,
  hasPermissions,
  hrManagerOnlyRoutes,
  organisationWhiteListRoutes,
  planningManagerOnlyRoutes,
  projectManagersOnlyRoutes,
  cuTenderManagerOnlyRoutes,
  projectTenderManagerOnlyRoutes,
  projectTeamOnlyRoutes,
  customerPortalAccessorOnlyRoutes,
} from './shared/util';
import client from './api';
import AuthService from '../app/services/auth.service';
import { connect } from 'react-redux';
import { ProgressIndicator } from '@fluentui/react';
import {
  AccessLevel,
  ORGANISATION_ID,
  IS_ALLOW_ONLY_ORG,
} from './types/permissions';
import _ from 'lodash';
import { getUserInformation } from './reducers/uprinceReducer';
import { getBuGroups } from './types/myDayPlanning';
import { getBuDropdownData } from './reducers/projectDayPlanningReducer';

class PrivateRoute extends React.Component<any, any> {
  authService = new AuthService();
  state = {
    loading: true,
    isAuthorize: false,
    project: '',
    projects: [],
    cu: null,
  };

  async componentDidMount() {
    await this.props.getUserInformation().then((response: any) => {
      let filterData = {
        title: null,
        contractingUnit:
          response?.payload?.data?.result?.contractingUnit?.sequnceCode, //sequenceCode as a string
        businessUnit: getBuGroups(),
      };
      this.props.getBuDropdownData(filterData);
    });
    if (getAccessingLevel() === AccessLevel.PROJECT) {
      if (this.authService.checkIsLogged()) {
        const loginResponse = await client.post('User/login');
        let projects = getUserProjectscodes(
          loginResponse?.data?.result?.projects
        );
        this.setState({
          projects: projects,
          project: getProject() ? getProject() : '',
        });
        this.checkAccessPermission(projects);
        // if (this.props.location.pathname === '/address-book') {
        //   this.setState({loading:false, isAuthorize:false, project:getProject()})
        // }

        // let projects = getUserProjectscodes(loginResponse?.data?.result?.projects)
        // if (projects && projects.length !== 0 && getProject()!! && projects.indexOf(getProject()!!) === -1) {
        //   this.setState({loading:false, isAuthorize:false, project:getProject()})
        // } else {
        //   this.setState({loading:false, isAuthorize:true, project:getProject()})
        // }
      }
    } else {
      this.checkAccessPermission(this.state.projects);
    }
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.checkAccessPermission(this.state.projects);
    }
  }

  render() {
    const { component: Component, currentUser, ...rest } = this.props;
    if (!currentUser) {
      // not logged in so redirect to login page with the return url
      return (
        <Redirect
          to={{ pathname: '/login', state: { from: this.props.location } }}
        />
      );
    }
    if (this.state.loading) {
      return <ProgressIndicator />;
    }
    if (
      IS_ALLOW_ONLY_ORG &&
      this.props.userInfo?.organisationId !== ORGANISATION_ID
    ) {
      return (
        <Redirect
          to={{
            pathname: '/permission--denied',
            state: {
              project: this.state.project,
              organisation: this.props.organization,
              cu: this.state.cu,
            },
          }}
        />
      );
    }
    return (
      <Route
        {...rest}
        render={(props) => (
          <div>
            {!this.state.isAuthorize && (
              <Redirect
                to={{
                  pathname: '/permission-denied',
                  state: {
                    project: this.state.project,
                    organisation: this.props.organization,
                    cu: this.state.cu,
                  },
                }}
              />
            )}
            <Component {...this.props} />
          </div>
        )}
      />
    );
  }

  checkAccessPermission = (projects: any[]) => {
    switch (getAccessingLevel()) {
      case AccessLevel.MY_ENV:
        if (this.props.location.pathname) {
          const paths = this.props.location.pathname.split('/');

          if (
            !hasPermissions(
              this.props.currentUser?.groups,
              this.props.organization,
              undefined
            ).isProjectManager
          ) {
            this.setState({ loading: false, isAuthorize: false });
          } else {
            this.setState({ loading: false, isAuthorize: true });
          }
        }
        break;

      case AccessLevel.ORGANIZATION:
        if (
          organisationWhiteListRoutes.indexOf(this.props.location.pathname) ===
          -1
        ) {
          if (
            !hasPermissions(
              this.props.currentUser?.groups,
              this.props.organization,
              undefined
            ).hasOrganisationPermission
          ) {
            this.setState({ loading: false, isAuthorize: false });
          } else {
            this.setState({ loading: false, isAuthorize: true });
          }
        } else {
          this.setState({ loading: false, isAuthorize: true });
        }

        break;
      case AccessLevel.CONTRACTING_UNIT:
        if (this.props.location.pathname) {
          const paths = this.props.location.pathname.split('/');

          if (
            !hasPermissions(
              this.props.currentUser?.groups,
              this.props.organization,
              getContractingUnit()?.replace('-', '')!!
            ).hasContractingUnitPermission
          ) {
            if (paths && _.isArray(paths) && paths.length > 3) {
              this.setState({ loading: false, isAuthorize: false });
            } else {
              this.setState({ loading: false, isAuthorize: true });
            }
          } else {
            this.setState({ loading: false, isAuthorize: true });
          }
        }
        break;
      case AccessLevel.CONTRACTING_UNIT_FEATURE:
        if (this.props.location.pathname) {
          const paths = this.props.location.pathname.split('/');
          const permissions = hasPermissions(
            this.props.currentUser?.groups,
            this.props.organization,
            getContractingUnit()?.replace('-', '')!!
          );

          // DO NOT CHANGE THE ORDER OF THE IF STATEMENTS !!!
          if (
            !permissions.isProjectManager ||
            !permissions.isHRManager ||
            !permissions.isPlanningManager
          ) {
            if (paths && _.isArray(paths) && paths.length > 3) {
              this.setState({ loading: false, isAuthorize: false });
            } else {
              this.setState({ loading: false, isAuthorize: true });
            }
          } else {
            this.setState({ loading: false, isAuthorize: true });
          }

          if (
            permissions.isPlanningManager &&
            paths.some((path: string) =>
              planningManagerOnlyRoutes.includes(path)
            )
          ) {
            this.setState({ loading: false, isAuthorize: true });
          }

          if (
            permissions.isHRManager &&
            paths.some((path: string) => hrManagerOnlyRoutes.includes(path))
          ) {
            this.setState({ loading: false, isAuthorize: true });
          }

          if (
            permissions.isBackOfficer &&
            paths.some((path: string) => backOfficersOnlyRoutes.includes(path))
          ) {
            this.setState({ loading: false, isAuthorize: true });
          }

          if (
            permissions.isCUTenderManager &&
            paths.some((path: string) =>
              cuTenderManagerOnlyRoutes.includes(path)
            )
          ) {
            this.setState({ loading: false, isAuthorize: true });
          }

          if (
            permissions.isProjectManager &&
            paths.some((path: string) =>
              projectManagersOnlyRoutes.includes(path)
            )
          ) {
            this.setState({ loading: false, isAuthorize: true });
          }
        }
        break;
      case AccessLevel.PROJECT:
        if (this.props.location.pathname) {
          const paths = this.props.location.pathname.split('/');
          const permissions = hasPermissions(
            this.props.currentUser?.groups,
            this.props.organization,
            getContractingUnit()?.replace('-', '')!!
          );

          if (
            projects &&
            projects.length !== 0 &&
            getProject()!! &&
            projects.indexOf(getProject()!!) === -1
          ) {
            this.setState({ loading: false, isAuthorize: false });
          } else if (
            permissions.isCustomerPortalUser &&
            !paths.some((path: string) =>
              customerPortalAccessorOnlyRoutes.includes(path)
            )
          ) {
            this.setState({ loading: false, isAuthorize: false });
          } else {
            this.setState({ loading: false, isAuthorize: true });
          }
        }
        break;
      case AccessLevel.PROJECT_FEATURE:
        const paths = this.props.location.pathname.split('/');
        const permissions = hasPermissions(
          this.props.currentUser?.groups,
          this.props.organization,
          getContractingUnit()?.replace('-', '')!!
        );
        if (!permissions.isProjectManager) {
          this.setState({ loading: false, isAuthorize: false });
        } else {
          this.setState({ loading: false, isAuthorize: true });
        }

        if (
          permissions.isProjectTenderManager &&
          paths.some((path: string) =>
            projectTenderManagerOnlyRoutes.includes(path)
          )
        ) {
          this.setState({ loading: false, isAuthorize: true });
        } else {
          if (!permissions.isProjectManager)
            this.setState({ loading: false, isAuthorize: false });
        }

        if (
          !permissions.isProjectTenderManager &&
          permissions.isProjectTeamManager &&
          paths.some((path: string) => projectTeamOnlyRoutes.includes(path))
        ) {
          this.setState({ loading: false, isAuthorize: true });
        } else {
          //this.setState({ loading: false, isAuthorize: false });
        }

        break;
      case AccessLevel.ORGANIZATION_FEATURE:
        if (
          !hasPermissions(
            this.props.currentUser?.groups,
            this.props.organization,
            getContractingUnit()?.replace('-', '')!!
          ).isApplicationManager
        ) {
          this.setState({ loading: false, isAuthorize: false });
        } else {
          this.setState({ loading: false, isAuthorize: true });
        }
        break;
      default:
        this.setState({ loading: false, isAuthorize: true });
        break;
    }
  };
}

const mapStateToProps = (state: any) => {
  return {
    organization: state.uprince.organization,
    userInfo: state.uprince.userInfo,
  };
};
// state.uprince.organization
const mapDispatchToProps = {
  getUserInformation: getUserInformation,
  getBuDropdownData: getBuDropdownData,
};

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);
