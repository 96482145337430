import React, { Component } from 'react';
import { connect } from 'react-redux';
import { IColumn, Selection } from '@fluentui/react/lib/DetailsList';
import history from '../../../history';

import ListPaneComponent from './component';
import { withRouter } from 'react-router';
import { WithTranslation, withTranslation } from 'react-i18next';
import { messageService } from '../../../services/messageService';
import {
  formData,
  Project,
  ProjectListItem,
  ProjectListPaneFilter,
} from '../../../types/project';
import { getContractingUnit, hasPermissions } from '../../../shared/util';
import ENV from '../../../../env.json';
import { PROJECT_DEF_COLUMNS } from '../../../types/types';
import { store } from '../../../..';

//import { getProjectDefinition } from '../../../reducers/projectReducer';

export interface State {
  redirect: boolean;
  isChange: boolean;
  loading: boolean;
  filter: ProjectListPaneFilter;
  announcedMessage: string;
  selectedListItemIds: [] | string[];
  selectedListItemSequenceCode: [] | string[];
  formData: Project;
  selectedListItems: ProjectListItem[];
}

export interface Props extends WithTranslation {
  listPaneItems: ProjectListItem[];
  reloadListPaneItem: () => void;
  handelFilter: (filter: ProjectListPaneFilter) => void;
  isDataLoaded?: boolean;
  isChange: boolean;
  filter: ProjectListPaneFilter;
  resourceTypes: [];
  loadMsg: string;
  toleranceStatesFilter: [];
  productStatesFilter: [];
  itemTypesFilter: [];
  handleSelectedListPaneItem: (selectedItemIds: [] | string[]) => void;
  formData: Project;
  projectId: string | null;
  projectDropDowns: any;
  reSizer: number;
  readProjectDropDownData: () => void;
  saveProjectStateAttr: () => void;
}

class ListPane extends Component<any, any> {
  private _Selection: Selection;
  // private _columns: IColumn[];
  private _columnsReSizer: IColumn[];
  subscription: any;
  titleClick: boolean;
  _buildColumns: IColumn[];

  constructor(props: any) {
    super(props);
    this.titleClick = false;
    this._Selection = new Selection({
      onSelectionChanged: () => {
        this.setState(
          {
            selectedListItemIds: this._getSelectedIds(),
            selectedListItemSequenceCode: this._getselectedSequenceCode(),
            selectedListItems: this._getSelectedListItems(),
          },
          this.handleSelectedItem
        );
      },
    });
    this.state = {
      selectedListItems: [],
      selectedListItemIds: [],
      selectedListItemSequenceCode: [],
      redirect: false,
      isChange: false,
      loading: false,
      announcedMessage: '',
      key: 'set',
      filter: new ProjectListPaneFilter(),
      formData: formData,
    };
    const { t } = this.props;

    {
      /* Build Columns*/
    }
    let columns: IColumn[] = [];
    ENV.LIST_PANE_COLUMNS.PROJECT_DEF.includes(PROJECT_DEF_COLUMNS.TITLE) &&
      columns.push({
        key: 'column1',
        name: t('title'),
        fieldName: 'title',
        minWidth: 100,
        maxWidth: 200,
        isRowHeader: true,
        isResizable: true,
        isSorted: true,
        isSortedDescending: false,
        onColumnClick: this._onColumnClick,
        data: 'string',
        isPadded: true,
      });
    ENV.LIST_PANE_COLUMNS.PROJECT_DEF.includes(
      PROJECT_DEF_COLUMNS.CIAW_NUMBER
    ) &&
      columns.push({
        key: 'column12',
        name: t('ciawNumber'),
        fieldName: 'ciawNumber',
        minWidth: 100,
        maxWidth: 150,
        isRowHeader: true,
        isResizable: true,
        onColumnClick: this._onColumnClick,
        data: 'string',
        isPadded: true,
      });
    ENV.LIST_PANE_COLUMNS.PROJECT_DEF.includes(PROJECT_DEF_COLUMNS.CUSTOMER) &&
      columns.push({
        key: 'column10',
        name: this.props.t('customer'),
        fieldName: 'customerId',
        minWidth: 100,
        maxWidth: 150,
        isRowHeader: true,
        isResizable: true,
        onColumnClick: this._onColumnClick,
        data: 'string',
        isPadded: true,
      });
    ENV.LIST_PANE_COLUMNS.PROJECT_DEF.includes(
      PROJECT_DEF_COLUMNS.COMPANY_NAME
    ) &&
      columns.push({
        key: 'column14',
        name: this.props.t('organization'),
        fieldName: 'customerCompanyName',
        minWidth: 100,
        maxWidth: 150,
        isRowHeader: true,
        isResizable: true,
        onColumnClick: this._onColumnClick,
        data: 'string',
        isPadded: true,
      });
    ENV.LIST_PANE_COLUMNS.PROJECT_DEF.includes(
      PROJECT_DEF_COLUMNS.PROJECT_MANAGER
    ) &&
      columns.push({
        key: 'column6',
        name: t('projectManager'),
        fieldName: 'projectManagerId',
        minWidth: 100,
        maxWidth: 150,
        isRowHeader: true,
        isResizable: true,
        onColumnClick: this._onColumnClick,
        data: 'string',
        isPadded: true,
      });
    ENV.LIST_PANE_COLUMNS.PROJECT_DEF.includes(
      PROJECT_DEF_COLUMNS.SITE_MANAGER
    ) &&
      columns.push({
        key: 'column11',
        name: this.props.t('siteManager'),
        fieldName: 'siteManagerName',
        minWidth: 100,
        maxWidth: 150,
        isRowHeader: true,
        isResizable: true,
        onColumnClick: this._onColumnClick,
        data: 'string',
        isPadded: true,
      });
    ENV.LIST_PANE_COLUMNS.PROJECT_DEF.includes(
      PROJECT_DEF_COLUMNS.PROJECT_STATUS
    ) &&
      columns.push({
        key: 'column7',
        name: t('projectStatus'),
        fieldName: 'projectStatus',
        minWidth: 100,
        maxWidth: 150,
        isRowHeader: true,
        isResizable: true,
        onColumnClick: this._onColumnClick,
        data: 'string',
        isPadded: true,
      });
    ENV.LIST_PANE_COLUMNS.PROJECT_DEF.includes(
      PROJECT_DEF_COLUMNS.PROJECT_ADDRESS
    ) &&
      columns.push({
        key: 'column13',
        name: t('projectAddress'),
        fieldName: 'projectAddress',
        minWidth: 100,
        maxWidth: 150,
        isRowHeader: true,
        isResizable: true,
        onColumnClick: this._onColumnClick,
        data: 'string',
        isPadded: true,
      });
    ENV.LIST_PANE_COLUMNS.PROJECT_DEF.includes(PROJECT_DEF_COLUMNS.SECTOR) &&
      columns.push({
        key: 'column9',
        name: this.props.t('sector'),
        fieldName: 'sectorName',
        minWidth: 100,
        maxWidth: 150,
        isRowHeader: true,
        isResizable: true,
        onColumnClick: this._onColumnClick,
        data: 'string',
        isPadded: true,
      });
    this._buildColumns = columns;

    // this._columns = [
    //   {
    //     key: 'column1',
    //     name: t('title'),
    //     fieldName: 'title',
    //     minWidth: 100,
    //     maxWidth: 200,
    //     isRowHeader: true,
    //     isResizable: true,
    //     isSorted: true,
    //     isSortedDescending: false,
    //     onColumnClick: this._onColumnClick,
    //     data: 'string',
    //     isPadded: true,
    //   },
    //   {
    //     key: 'column12',
    //     name: t('ciawNumber'),
    //     fieldName: 'ciawNumber',
    //     minWidth: 100,
    //     maxWidth: 200,
    //     isRowHeader: true,
    //     isResizable: true,
    //     onColumnClick: this._onColumnClick,
    //     data: 'string',
    //     isPadded: true,
    //   },
    //   {
    //     key: 'column10',
    //     name: this.props.t('customer'),
    //     fieldName: 'customerId',
    //     minWidth: 100,
    //     maxWidth: 150,
    //     isRowHeader: true,
    //     isResizable: true,
    //     onColumnClick: this._onColumnClick,
    //     data: 'string',
    //     isPadded: true,
    //   },
    //   {
    //     key: 'column6',
    //     name: t('projectManager'),
    //     fieldName: 'projectManagerId',
    //     minWidth: 100,
    //     maxWidth: 150,
    //     isRowHeader: true,
    //     isResizable: true,
    //     onColumnClick: this._onColumnClick,
    //     data: 'string',
    //     isPadded: true,
    //   },
    //   {
    //     key: 'column11',
    //     name: this.props.t('siteManager'),
    //     fieldName: 'siteManagerName',
    //     minWidth: 100,
    //     maxWidth: 150,
    //     isRowHeader: true,
    //     isResizable: true,
    //     onColumnClick: this._onColumnClick,
    //     data: 'string',
    //     isPadded: true,
    //   },
    //   {
    //     key: 'column7',
    //     name: t('projectStatus'),
    //     fieldName: 'projectStatus',
    //     minWidth: 100,
    //     maxWidth: 150,
    //     isRowHeader: true,
    //     isResizable: true,
    //     onColumnClick: this._onColumnClick,
    //     data: 'string',
    //     isPadded: true,
    //   },
    //   {
    //     key: 'column13',
    //     name: t('projectAddress'),
    //     fieldName: 'projectAddress',
    //     minWidth: 100,
    //     maxWidth: 150,
    //     isRowHeader: true,
    //     isResizable: true,
    //     onColumnClick: this._onColumnClick,
    //     data: 'string',
    //     isPadded: true,
    //   },
    //   {
    //     key: 'column9',
    //     name: this.props.t('sector'),
    //     fieldName: 'sectorName',
    //     minWidth: 100,
    //     maxWidth: 150,
    //     isRowHeader: true,
    //     isResizable: true,
    //     onColumnClick: this._onColumnClick,
    //     data: 'string',
    //     isPadded: true,
    //   },
    // ];
    this._columnsReSizer = [
      {
        key: 'column1',
        name: this.props.t('title'),
        fieldName: 'title',
        minWidth: 165,
        maxWidth: 200,
        isResizable: true,
        onColumnClick: this._onColumnClick,
      },
    ];
  }

  componentDidMount() {
    this.subscription = messageService.getMessage().subscribe((data: any) => {
      if (data) {
        if (data.data.isNewPbs) {
          this._Selection.selectToKey('', true);
        }
        if (data.data.resetListPaneSelection) {
          this._Selection.setAllSelected(false);
          this.resetSelection();
        }

        if (data.data.titleClick) {
          this.titleClick = data.data.titleClick;
        }
      }
    });
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevProps.isDataLoaded != this.props.isDataLoaded) {
      if (this.props.isDataLoaded) {
        this.resetSelection();
      }
    }
  }

  handleListPaneItemClick = (id: string) => {
    let currentUser = store.getState().uprince?.currentUser;
    console.log('bbb', currentUser);
    let isProjectTenderManager = hasPermissions(
      currentUser?.groups
    ).isProjectTenderManager;

    const isCustomerPortalUser = hasPermissions(
      currentUser?.groups
    ).isCustomerPortalUser;

    if (isCustomerPortalUser) {
      history.push(
        `/CU/${getContractingUnit()}/project/${id}/project-planning?from-plan-board=true`
      );
      return;
    }

    if (!this.titleClick && !isProjectTenderManager) {
      history.push(`/CU/${getContractingUnit()}/project/${id}`);
    } else if (this.titleClick) {
      history.push(
        `/CU/${getContractingUnit()}/project/${id}/project-definition`
      );
    } else {
      history.push(`/CU/${getContractingUnit()}/project/${id}/contract`);
    }
    this.titleClick = false;
  };

  handleSelectedItem = () => {
    this.props.handleSelectedListPaneItem(this.state.selectedListItemIds);
    if (this.state.selectedListItemSequenceCode) {
      let lastId = this.state.selectedListItemSequenceCode.pop();
      if (lastId) {
        this.handleListPaneItemClick(lastId);
      }
    }
  };

  handleFilterChange = (filter: ProjectListPaneFilter) => {
    this.props.handelFilter(filter);
  };

  handleFilterSortChange = () => {
    this.props.handelFilter(this.state.filter);
  };

  resetSelection = () => {
    if (
      this.state.formData &&
      this.state.formData.id &&
      this.props.isDataLoaded
    ) {
      this._Selection.setKeySelected(this.state.formData.id, true, false);
    } else {
      this._Selection.selectToKey('', true);
    }
  };

  render() {
    return (
      <div>
        <ListPaneComponent
          listPaneItems={
            this.props.listPaneItems ? this.props.listPaneItems : []
          }
          selection={this._Selection}
          handleListPaneItemClick={(id: string) =>
            this.handleListPaneItemClick(id)
          }
          resetSelection={() => this.resetSelection()}
          formData={this.state.formData}
          columns={
            this.props.reSizer === 300
              ? this._columnsReSizer
              : this._buildColumns
          }
          filter={this.props.filter}
          loadMsg={this.props.loadMsg}
          handleFilterChange={(filter: ProjectListPaneFilter) =>
            this.handleFilterChange(filter)
          }
          isDataLoaded={this.props.isDataLoaded}
          dropDownOptions={this.props.projectDropDowns}
          key={this.state.key}
        />
      </div>
    );
  }

  private _getSelectedIds() {
    let deleteList: any = [];
    let SelectedItem = this._Selection.getSelection() as ProjectListItem[];
    if (SelectedItem) {
      deleteList = SelectedItem.map((item: any) => {
        return item ? item.sequenceCode : null;
      });
    }
    return deleteList;
  }

  private _getselectedSequenceCode() {
    let selectedList: any = [];
    let selectedItem = this._Selection.getSelection() as ProjectListItem[];
    if (selectedItem) {
      selectedList = selectedItem.map((item: any) => {
        return item ? item.sequenceCode : null;
      });
    }
    return selectedList;
  }

  private _getSelectedListItems = () => {
    return this._Selection.getSelection() as ProjectListItem[];
  };

  private _onColumnClick = (
    ev: React.MouseEvent<HTMLElement>,
    column: IColumn
  ): void => {
    const newColumns: IColumn[] = this._buildColumns.slice();
    const currColumn: IColumn = newColumns.filter(
      (currCol) => column.key === currCol.key
    )[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
        let filter = this.state.filter;

        let fieldName = currColumn.fieldName;

        let sortby = currColumn.isSortedDescending ? 'desc' : 'asc';
        filter.sorter.attribute = fieldName ? fieldName : null;
        filter.sorter.order = sortby ? sortby : null;
        this.setState(
          {
            filter: filter,
          },
          this.handleFilterSortChange
        );
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });
  };
}

const mapStateToProps = (state: any, ownProps: any) => {
  return {
    // toleranceStatesFilter:state.projectBreakdown.toleranceStatesFilter,
    // productStatesFilter: state.projectBreakdown.productStatesFilter,
    // itemTypesFilter: state.projectBreakdown.itemTypesFilter,
    isChange: state.project.isChange,
    projectDropDowns: state.project.projectDropDowns,
  };
};

const mapDispatchToProps = {};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ListPane))
);
