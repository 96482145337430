import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FocusZone } from 'office-ui-fabric-react/lib/FocusZone';
import { FontIcon, IRectangle, List, Spinner, SpinnerSize, TooltipHost } from 'office-ui-fabric-react';
import { getTheme, ITheme, mergeStyleSets, registerIcons } from 'office-ui-fabric-react/lib/Styling';
import { uPrinceTheme } from '../../../theme';
import UprinceLogger from '../Logger/logger';
import { getFileType } from './getFileType';
import { SvgIcons } from '../svgIcons/svgIcons';
import DocumentPreviewModal from './documentPreviewModal';
import { after } from 'lodash';
 
registerIcons(SvgIcons);

const theme: ITheme = getTheme();
const { palette, fonts } = theme;
const ROWS_PER_PAGE = 3;
const MAX_ROW_HEIGHT = 250;
const classNames = mergeStyleSets({
  listGridExample: {
    overflow: 'hidden',
    fontSize: 0,
    position: 'relative',
  },
  listGridExampleTileAnimated: {
    textAlign: 'center',
    outline: 'none',
    position: 'relative',
    // marginLeft:1,
    float: 'left',
    // background: palette.neutralLighter,
    transition: 'transform 0.8s ease-in-out',
    transform: 'rotateY(180deg)',

    selectors: {
      'focus:after': {
        content: '',
        position: 'absolute',
        left: 2,
        right: 2,
        top: 2,
        bottom: 2,
        boxSizing: 'border-box',
        border: `1px solid ${palette.white}`,
      },
      // ':hover': {
      //     transition: 'transform 0.8s ease-in-out',
      //     transform: 'rotateY(180deg)',
      // },
    },
  },
  listGridExampleTile: {
    textAlign: 'center',
    outline: 'none',
    position: 'relative',
    // marginLeft:1,
    float: 'left',
    // background: palette.neutralLighter,
    transition: 'transform 0.8s ease-in-out',
    transform: 'rotateY(0deg)',

    selectors: {
      'focus:after': {
        content: '',
        position: 'absolute',
        left: 2,
        right: 2,
        top: 2,
        bottom: 2,
        boxSizing: 'border-box',
        border: `1px solid ${palette.white}`,
      },
      // ':hover': {
      //     transition: 'transform 0.8s ease-in-out',
      //     transform: 'rotateY(180deg)',
      // },
    },
  },
  listGridExampleSizer: {
    paddingBottom: '100%',
  },
  listGridExamplePadder: {
    position: 'absolute',
    left: 2,
    top: 2,
    right: 2,
    bottom: 2,
  },
  fileNameStyle: {
    background: 'rgba(0, 0, 0, 0.3)',
    color: '#FFFFFF',
    position: 'absolute',
    padding: 10,
    bottom: 0,
    left: 0,
    width: '100%',
    fontSize: fonts.small.fontSize,
    boxSizing: 'border-box',
  },
  listGridExampleLabel: {
    color: uPrinceTheme.palette.themePrimary,
    position: 'absolute',
    padding: 10,
    paddingRight: 5,
    bottom: '68%',
    textAlign: 'end',
    left: 0,
    width: '100%',
    fontSize: fonts.small.fontSize,
    boxSizing: 'border-box',
  },
  listGridExampleLabelAnimated: {
    color: uPrinceTheme.palette.themePrimary,
    position: 'absolute',
    padding: 10,
    paddingRight: 5,
    bottom: '30%',
    textAlign: 'end',
    left: 0,
    width: '100%',
    fontSize: fonts.small.fontSize,
    boxSizing: 'border-box',
  },
  listGridExampleImage: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
  listGridExampleImageAnimated: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    filter: 'blur(4px)',
    height: '100%',

  },
  checkMark: {
    width: '12px',
    height: '12px',
    cursor: 'pointer',
    marginLeft: '30%',
    position: 'absolute',
    zIndex:'1',
    top: '4px',
    right: '4px',
    borderRadius: '50%',
    border: '2px solid',
    borderColor: '#eee',  
  },
  onClick: {
    background: 'white',
    width: '15px',
    height: '15px',
    cursor: 'pointer',
    marginLeft: '30%',
    position: 'absolute',
    zIndex:'1',
    top: '3px',
    borderRadius: '25px',
    selectors: {
      '&:after': {
      border: 'solid darkgrey',
      borderWidth: '0 3px 3px 0',
      position: 'absolute',
      // zIndex:'1',
      left: '5px',
      top: '1px',
      width: '3px',
      height: '9px',
      display: 'block',
      content: '""',
      transform: 'rotate(45deg)',
    }}
  },
  isApproved: {
    background: 'green',
    width: '15px',
    height: '15px',
    cursor: 'pointer',
    marginLeft: '30%',
    position: 'absolute',
    zIndex:'1',
    top: '3px',
    borderRadius: '25px',
    selectors: {
      '&:after': {
      border: 'solid white',
      borderWidth: '0 3px 3px 0',
      position: 'absolute',
      // zIndex:'1',
      left: '5px',
      top: '1px',
      width: '3px',
      height: '9px',
      display: 'block',
      content: '""',
      transform: 'rotate(45deg)',
    }}
  },
  defaultCheckbox: {
    position: 'absolute',
    opacity: '0',
    cursor: 'pointer',
    height: '0',
    width: '0',
    selectors: {
      '&:checked + label': {
        '&:after': {
          display:'block'
        },
      },
    },
  },
  
});

export interface UploadedImage {
  id: string | null;
  image: string | null;
}

const ImagePreview = (props: {
  uploadedImages: any,
  handleImageDelete: (id: string) => void,
  transition: boolean,
  disable?: boolean
  isDelete?: boolean,
  checkedImages?: any,
  onCheckedItemsUpdate: (updatedCheckedItems: any) => void;
  hideCopyButton?: boolean,
  showCheckbox?:boolean
  // onCheckboxClick?: (id: string) => void,
}) => {
  const [isOpenImageModal, setIsOpenImageModal]: any = useState(false);
  const [selectedImage, setSelectedImage]: any = useState(null);
  const [selectedType, setSelectedType]: any = useState('');
  const [transition, setTransition]: any = useState(false);
  const [uploadedImages, setUploadedImages]: any = useState([]);
  // const checkedItemList:any[] = [];
  const [checkedItemList, setCheckedItemList]: any = useState([]);

  useEffect(() => {
    setTransition(props.transition);
  }, [props.transition]);
  useEffect(() => {
    setUploadedImages(props.uploadedImages);
  }, [props.uploadedImages]);

  UprinceLogger.log('uploadedImages ImageGrid', props.uploadedImages);

  const { t } = useTranslation();
  const columnCount = React.useRef(0);
  const rowHeight = React.useRef(0);

  const handleImageClick = (item: any) => {
    if(item!=null && item!=undefined){      
      const imageDetail = {
          imageId: item.id,
          link: item.thumbnail,
          type: item.type,
          isJournal: item.isJournal,
          isExtrawork: item.isExtrawork,
      }
      updateItems(imageDetail);
  }
  // setCheckedItemList(props.imageIdList);
}

  const updateItems = (imageDetail:any) => {
    if(imageDetail){
      props.onCheckedItemsUpdate(imageDetail);
    }   
  };

  const openImageModal = (image: any, type: string) => {
    setSelectedImage(image);
    setSelectedType(type);
    setIsOpenImageModal(true);
  };

  const isChecked = (itemId: string, checkedItemList: any) => {
    console.log(checkedItemList.includes(itemId));
      if(checkedItemList.includes(itemId)){
        return true;
      }
      else{
        return false;
      }
  };  

  const renderPreview = (item?: { thumbnail: string, id: string, extension: string, isApproved: boolean, isChecked: boolean } | undefined) => {
    let fileType = getFileType(item?.thumbnail, item?.extension);
    if (fileType && fileType.type === 'image') {
      if(item?.isApproved){
        return (
          <div className={`oneImage ${JSON.stringify(item?.id)}`}>
            <label>            
      <span id={`checkbox-${item?.id}`} className={`${classNames.isApproved} ${classNames.checkMark}`}
      onClick={() =>{}}>
      </span>
      </label>
            <img alt={item?.id} onClick={(event) => {
              event.preventDefault();
              openImageModal(item?.thumbnail, 'image');
              //window.open(item?.thumbnail);
            }} src={item?.thumbnail} className={classNames.listGridExampleImage}/>
            <span className={classNames.listGridExampleLabel}>&nbsp;
              {(!props.disable) ? (props.isDelete && item?.id != null) ? <span onClick={() => {
                props.handleImageDelete(item?.id);
              }} style={{ cursor: 'pointer' }}>
                <FontIcon iconName="Delete" className="removeIcon"/></span> : false : false}
                </span>
          
        </div>
      );
      }
      else{
        console.log("props.showCheckbox",props.showCheckbox);
        return (          
          <div className={`oneImage ${JSON.stringify(item?.id)}`}>
             {props.showCheckbox && (
              <label>
              <input type="checkbox"      
          id={`checkbox-${item?.id}`} // Using a unique identifier for each checkbox
          className={classNames.defaultCheckbox}
          style={{
            width: '15px',
            height: '15px',
            // anand
            cursor: 'pointer',
            marginLeft: '30%',
            position: 'absolute',
            borderRadius: '50%',
            zIndex:'1'
            
          }}
          onChange={(event) =>{
            const isChecked = event.target.checked;
            console.log('Checkbox checked:', isChecked);
              if(item?.id!=null && item?.id!=undefined){
                handleImageClick(item);  
              }          
          }}
          checked={checkedItemList.includes(item?.id || "")} 
        />
        <span id={`checkbox-${item?.id}`} className={`${classNames.checkMark} ${isChecked(item?.id || item?.thumbnail || '', checkedItemList) ? classNames.onClick : ''}`}
        onClick={() =>{
          const idIndex = checkedItemList.indexOf(item?.id);
          const thumbnailIndex = checkedItemList.indexOf(item?.thumbnail);
          if(item?.id!=null && item?.id!=undefined){
            if(idIndex === -1){
              checkedItemList.push(item?.id);         
              setCheckedItemList(checkedItemList);
              console.log('inside if',checkedItemList)
            }
            else{
              checkedItemList.splice(idIndex,1); 
              setCheckedItemList(...checkedItemList);
              console.log('Inside else',checkedItemList)       
            }
          }
          else{
            if(thumbnailIndex === -1){
              checkedItemList.push(item?.thumbnail);         
              setCheckedItemList(checkedItemList);
              console.log('inside if',checkedItemList)
            }
            else{
              checkedItemList.splice(idIndex,1); 
              setCheckedItemList(...checkedItemList);
              console.log('Inside else',checkedItemList)       
            }
          }
          
        }}
        >
        </span>
              </label>
             )}            
            <img alt={item?.id} onClick={(event) => {
              event.preventDefault();
              openImageModal(item?.thumbnail, 'image');
              //window.open(item?.thumbnail);
            }} src={item?.thumbnail} className={classNames.listGridExampleImage}/>
            <span className={classNames.listGridExampleLabel}>&nbsp;
              {(!props.disable) ? (props.isDelete && item?.id != null) ? <span onClick={() => {
                props.handleImageDelete(item?.id);
              }} style={{ cursor: 'pointer' }}>
                <FontIcon iconName="Delete" className="removeIcon"/></span> : false : false}
                </span>
          
        </div>
      );
      }        
    } else {
      return (
        <div className={'icon'}>
          <img alt={item?.id} onClick={(event) => {
            event.preventDefault();
            if (fileType.type === 'excel' || fileType.type === 'powerPoint' || fileType.type === 'document') {
              window.open(item?.thumbnail, 'file');
            } else if (fileType.type === 'pdf') {
              let res = item?.thumbnail.split('.').pop();
              if (res === 'pdf' || res === 'PDF') {
                window.open(item?.thumbnail, 'file');
              } else {
                openImageModal(item?.thumbnail, 'file');
              }
            } else {
              openImageModal(item?.thumbnail, 'file');
            }
          }} src={fileType.image} className={classNames.listGridExampleImage}/>
          <span className={classNames.listGridExampleLabel}>&nbsp;
            {(!props.disable) ? (props.isDelete && item?.id != null) ? <span onClick={() => {
              props.handleImageDelete(item?.id);
            }} style={{ cursor: 'pointer' }}><FontIcon iconName="Delete"
                                                       className="removeIcon"/></span> : false : false}
              </span>
        </div>
      );
    }

  };

  const getItemCountForPage = React.useCallback((itemIndex?: number | undefined, visibleRect?: IRectangle | undefined) => {
    if (itemIndex === 0 && visibleRect) {
      columnCount.current = Math.ceil(visibleRect.width / MAX_ROW_HEIGHT);
      rowHeight.current = Math.floor(visibleRect.width / columnCount.current);
    }
    return columnCount.current * ROWS_PER_PAGE;
  }, []);

  const onRenderCell = React.useCallback(
    (item?: { thumbnail: string, id: string, extension: string, isApproved: boolean, isChecked: boolean } | undefined, index?: number | undefined, isScrolling?: boolean | undefined) => {
      return (
        <div
          className={classNames.listGridExampleTile}
          data-is-focusable
          style={{
            width: '20%',
          }}
        >
          <div className={classNames.listGridExampleSizer}>
            <div className={classNames.listGridExamplePadder}>
              {renderPreview(item)}
            </div>
          </div>
          {item?.thumbnail !== '/465d79325d28da8ce03933e8285eca4b.jpg' && <TooltipHost
            content={getFileName(item?.thumbnail!!)}
            id={'onRenderCell'}
          >
            <span className={classNames.fileNameStyle}>{getShortFileName(item?.thumbnail!!)}</span>
          </TooltipHost>}
        </div>
      );
    },
    [columnCount.current],
  );

  const onRenderCellAnimated = React.useCallback(
    (item?: { thumbnail: string, id: string, isLast: boolean, extension: string,isApproved: boolean, isChecked: boolean } | undefined, index?: number | undefined, isScrolling?: boolean | undefined) => {
      if (item?.isLast) {
        return (
          <div
            className={(item?.isLast) ? classNames.listGridExampleTileAnimated : classNames.listGridExampleTile}
            data-is-focusable
            style={{
              width: 50 / columnCount.current + '%',
            }}
          >
            <div className={classNames.listGridExampleSizer}>
              <div className={classNames.listGridExamplePadder}>

                {/*<img src={dummyimage} className={classNames.listGridExampleImageAnimated}/>*/}
                <span className={classNames.listGridExampleLabelAnimated}>&nbsp;
                  <Spinner size={SpinnerSize.large}/>
              </span>
              </div>
            </div>
          </div>
        );
      } else {
        return (
          <div
            className={classNames.listGridExampleTile}
            data-is-focusable
            style={{
              width: '20%',
            }}
          >
            <div className={classNames.listGridExampleSizer}>
              <div className={classNames.listGridExamplePadder}>
                {renderPreview(item)}
              </div>
            </div>
            {item?.thumbnail !== '/465d79325d28da8ce03933e8285eca4b.jpg' && <TooltipHost
              content={getFileName(item?.thumbnail!!)}
              id={'onRenderCellAnimated'}
            >
              <span className={classNames.fileNameStyle}>{getShortFileName(item?.thumbnail!!)}</span>
            </TooltipHost>}
          </div>
        );
      }

    },
    [columnCount.current],
  );



  const getFileName = (item: string) => {
    let decoded = decodeURIComponent(item);
    let fileName = decoded.substring(decoded.indexOf('M') + 1);

    return fileName;
  };

  const getShortFileName = (item: string) => {
    let shortFileName = ((getFileName(item)).substring(0, 10)) + '...';

    return shortFileName;
  };

  const getPageHeight = (): number => {
    return rowHeight.current * ROWS_PER_PAGE;
  };

  const getImages = () => {

    if (props.uploadedImages && props.uploadedImages.length > 0) {
      let images = props.uploadedImages.map((item: any) => {
        return { thumbnail: item.image, id: item.id, isLast: item.isLast, extension: item?.extension, type: item.type, isExtrawork: item.isExtrawork, isJournal: item.isJournal, isApproved: item.isApproved };
      });
      return images;
    } else {
      return [];
    }

  };
  return (
    <div>
      <FocusZone>
        {transition ? <List
          className={classNames.listGridExample}
          items={getImages()}
          getItemCountForPage={getItemCountForPage}
          // eslint-disable-next-line react/jsx-no-bind
          getPageHeight={getPageHeight}
          renderedWindowsAhead={4}
          onRenderCell={onRenderCellAnimated}
        /> : <List
          className={classNames.listGridExample}
          items={getImages()}
          getItemCountForPage={getItemCountForPage}
          // eslint-disable-next-line react/jsx-no-bind
          getPageHeight={getPageHeight}
          renderedWindowsAhead={4}
          onRenderCell={onRenderCell}
        />}
      </FocusZone>
      <DocumentPreviewModal type={selectedType} openImageModal={() => {
        setIsOpenImageModal(false);
      }} isOpen={isOpenImageModal} image={selectedImage}/>
    </div>
  );

};

export default ImagePreview;
