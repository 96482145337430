import {
  DirectionalHint,
  Label,
  Link,
  Stack,
  Text,
  TooltipHost,
} from '@fluentui/react';
import { mergeStyleSets } from '@fluentui/react/lib/Styling';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { iconColors, uPrinceTheme } from '../../../../theme';
import { VPShortCutPaneItem } from '../../../types/visualPlan';
import { saveVPStateAttr } from '../../../reducers/visualPlanReducer';
import { RESOURCE_TYPE_IDS } from '../../../shared/util';

const classNames = mergeStyleSets({
  wrapper: {
    height: 'auto !important',
    position: 'relative',
    minHeight: '400px !important',
  },
  selected: {
    fontWeight: 'bold',
    color: '#854114!important',
  },
  disabled: {
    color: '#cccccc!important',
  },
});

const allFilterItem: VPShortCutPaneItem = {
  id: null,
  name: null,
  type: null,
  key: null,
  value: '0',
};

const resourceTableFilterItem: VPShortCutPaneItem = {
  id: '100',
  name: null,
  type: 'table',
  key: '100',
  value: '100',
};

const resourceWeekTableFilterItem: VPShortCutPaneItem = {
  id: '200',
  name: null,
  type: 'weektable',
  key: '200',
  value: '200',
};

const orgTaxonomyFilterItem: VPShortCutPaneItem = {
  id: '300',
  name: null,
  type: 'orgTaxonomy',
  key: '300',
  value: '300',
};

const midtermCapacityFilterItem: VPShortCutPaneItem = {
  id: '400',
  name: null,
  type: 'midtermCapacity',
  key: '400',
  value: '400',
};

const projectTeamCapacityFilterItem: VPShortCutPaneItem = {
  id: '500',
  name: null,
  type: 'projectTeamCapacity',
  key: '500',
  value: '500',
};

function LinkComponent({
  currentActiveSection,
  setFilterOption,
  label,
  key,
  selectedKey,
  disabled,
}: any) {
  const { t } = useTranslation();
  const getColor = () => {
    if (currentActiveSection === selectedKey) {
      return iconColors.iconActiveColor;
    }
    if (disabled) {
      return iconColors.iconDisabledColor;
    }

    return uPrinceTheme.palette.themePrimary;
  };

  return (
    <Link
      key={key}
      className={`${
        currentActiveSection === selectedKey ? classNames.selected : ''
      }`}
      href="#"
      onClick={() => {
        setFilterOption(resourceTableFilterItem);
      }}
      disabled={disabled}
    >
      <TooltipHost
        content={t('table')}
        id="filter-opt-1"
        calloutProps={{ gapSpace: 0 }}
        directionalHint={DirectionalHint.rightCenter}
      >
        <i
          className="ms-Icon ms-Icon--ViewAll"
          aria-labelledby="filter-opt-1"
          aria-hidden="true"
          style={{ color: getColor() }}
        ></i>
      </TooltipHost>
      <i
        className="filter-icon ms-Icon ms-Icon--ViewAll filter-icon"
        aria-hidden="true"
        style={{ color: getColor() }}
      ></i>
      <Text style={{ color: getColor(), fontSize: 13 }} key="table">
        {' '}
        {label}
      </Text>
    </Link>
  );
}

export const ShortCutPaneComponent = (props: {
  toggleOverlay: () => void;
  toggleOverlayFwd: () => void;
  divClass: string;
  shortCutPaneFilters: any;
  currentActiveSection: string | null;
  handelShortCutPaneFilter: (selectedItem: VPShortCutPaneItem) => void;
  resetFilter: () => void;
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [currentActiveSection, setCurrentActiveSection] = useState<
    string | null
  >(null);
  const selectedVpResourceBarType = useSelector(
    (state: any) => state?.vp?.selectedVpResourceBarType
  );
  const selectedProjectItemType = useSelector(
    (state: any) => state?.vp?.selectedProjectItemType
  );

  const setFilterOption = (selectedItem: VPShortCutPaneItem) => {
    if (selectedItem.type === 'all') {
      props.resetFilter();
    }
    dispatch(
      saveVPStateAttr('selectedPOVPshortcutpaneItems', selectedItem.key)
    );
    dispatch(saveVPStateAttr('selectedVpResourceBarType', selectedItem.value));
    setCurrentActiveSection(selectedItem.key);
    // props.handelShortCutPaneFilter(selectedItem);
  };

  useEffect(() => {
    dispatch(saveVPStateAttr('selectedVpResourceBarType', '0'));
    // setFilterOption(resourceTableFilterItem);
    // dispatch(saveVPStateAttr('selectedProjectItemType', '5'));
    return () => {
      dispatch(saveVPStateAttr('selectedProjectItemType', '5'));
    };
  }, []);

  useEffect(() => {
    if (selectedProjectItemType == '5') {
      setFilterOption(resourceTableFilterItem);
    } else if (selectedProjectItemType == '6') {
      setFilterOption(resourceWeekTableFilterItem);
    } else if (selectedProjectItemType == '7') {
      setFilterOption(midtermCapacityFilterItem);
    }
  }, [selectedProjectItemType]);

  // const renderFilterItems = () => {
  //   if (props.shortCutPaneFilters && props.shortCutPaneFilters.requestTypes) {
  //     return props.shortCutPaneFilters?.requestTypes?.map(
  //       (item: any, index: number) => {
  //         let divider = false;
  //
  //         return (
  //           <div key={item.key + 'div'}>
  //             {divider && (
  //               <hr
  //                 key={item.key + 'hr'}
  //                 style={{ marginBottom: 15, borderTop: '1px solid #d2d2d2' }}
  //               />
  //             )}
  //             <Link
  //               key={item.key + 'link'}
  //               className={`${
  //                 props.currentActiveSection === item.key
  //                   ? classNames.selected
  //                   : ''
  //               }`}
  //               href="#"
  //               onClick={() => {
  //                 setFilterOption(item);
  //               }}
  //             >
  //               <TooltipHost
  //                 key={item.key + 't'}
  //                 content={item.name}
  //                 id="filter-opt-1"
  //                 calloutProps={{ gapSpace: 0 }}
  //                 directionalHint={DirectionalHint.rightCenter}
  //               >
  //                 <i
  //                   key={item.id + 'i'}
  //                   className="ms-Icon ms-Icon--GotoToday"
  //                   aria-labelledby="filter-opt-1"
  //                   aria-hidden="true"
  //                   style={
  //                     props.currentActiveSection === item.key
  //                       ? { color: iconColors.iconActiveColor }
  //                       : { color: uPrinceTheme.palette.themePrimary }
  //                   }
  //                 ></i>
  //               </TooltipHost>
  //               <i
  //                 key={item.id + 'i2'}
  //                 className="filter-icon ms-Icon ms-Icon--GotoToday filter-icon"
  //                 aria-hidden="true"
  //                 style={
  //                   props.currentActiveSection === item.key
  //                     ? { color: iconColors.iconActiveColor }
  //                     : { color: uPrinceTheme.palette.themePrimary }
  //                 }
  //               ></i>
  //               <Text key={item.key}>{item.text} </Text>
  //             </Link>
  //           </div>
  //         );
  //       }
  //     );
  //   }
  //   return <div></div>;
  // };

  return (
    <div className={`${props.divClass} ${classNames.wrapper}`}>
      <div className="inner-container">
        <i
          onClick={props.toggleOverlay}
          className="ms-Icon ms-Icon--Back back-arrow"
          aria-hidden="true"
        ></i>
        <i
          onClick={props.toggleOverlayFwd}
          className="ms-Icon ms-Icon--Forward fwd-arrow"
          aria-hidden="true"
        ></i>

        <div className="filter-data filter-title">
          <TooltipHost
            content={
              selectedVpResourceBarType === RESOURCE_TYPE_IDS.LABOURS
                ? t('organisation')
                : t('warehouse')
            }
            // This is the important part!
            id="filter-opt-title"
            calloutProps={{ gapSpace: 0 }}
            directionalHint={DirectionalHint.rightCenter}
          >
            <Label
              aria-describedby={'filter-opt-title'}
              className={'short-cut-pane-label'}
            >
              {selectedVpResourceBarType === RESOURCE_TYPE_IDS.LABOURS
                ? t('organisation')
                : t('warehouse')}
            </Label>
          </TooltipHost>
        </div>

        <div className="filter-option-list">
          <Stack
            // gap={15}
            verticalFill
            styles={{
              root: {
                width: '100%',
                verticalAlign: 'center',
                gap: 15,
              },
            }}
          >
            <LinkComponent
              currentActiveSection={currentActiveSection}
              setFilterOption={() => setFilterOption(allFilterItem)}
              key={'all'}
              label={t('all')}
              selectedKey={null}
            />

            <LinkComponent
              currentActiveSection={currentActiveSection}
              setFilterOption={() => setFilterOption(resourceTableFilterItem)}
              key={'table'}
              label={t('resourceTable')}
              selectedKey={'100'}
              disabled={currentActiveSection === '300'}
            />

            <LinkComponent
              currentActiveSection={currentActiveSection}
              setFilterOption={() =>
                setFilterOption(resourceWeekTableFilterItem)
              }
              key={'weekTable'}
              label={t('weekTable')}
              selectedKey={'200'}
              disabled={currentActiveSection === '300'}
            />

            <LinkComponent
              currentActiveSection={currentActiveSection}
              setFilterOption={() => setFilterOption(midtermCapacityFilterItem)}
              key={'midtermCapacity'}
              label={t('midtermCapacity')}
              selectedKey={'400'}
            />

            <LinkComponent
              currentActiveSection={currentActiveSection}
              setFilterOption={() =>
                setFilterOption(projectTeamCapacityFilterItem)
              }
              key={'projectTeamCapacity'}
              label={t('projectTeamCapacity')}
              selectedKey={'500'}
            />

            <LinkComponent
              currentActiveSection={currentActiveSection}
              setFilterOption={() => setFilterOption(orgTaxonomyFilterItem)}
              key={'organisationTaxonomy'}
              label={t('organisationTaxonomy')}
              selectedKey={'300'}
            />
            {/*{renderFilterItems()}*/}
          </Stack>
        </div>
      </div>
    </div>
  );
};

// const mapStateToProps = (state: any) => ({});

// const mapDispatchToProps = {};

export default ShortCutPaneComponent;
// connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(ShortCutPaneComponent);
